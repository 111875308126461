import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web'

// set keycloak config options
const keycloakInitConfig = {
    onLoad: 'login-required',
};

// get a new instance of keycloak using the server settings
const keycloak = Keycloak({
    url: window.location.protocol+"//"+window.location.host+"/auth/",
    realm: "qsizer",
    clientId: "qsizer-ui"
  });

ReactDOM.render(
    <React.StrictMode>
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={keycloakInitConfig}
        >
            <App keycloak={keycloak}/>
        </ReactKeycloakProvider>
    </React.StrictMode>,
    document.getElementById('root')
);


