import { gql } from "@apollo/client";

export const SET_QUORT_MUTATION = gql`
mutation InsertQuote($quoteData: InsertQuoteInput!) {
  insertQuote(quoteData:$quoteData) {
  description
  quantity
  item
  model
  selected_nic
  sku
  drive_code
  } 
}
`;