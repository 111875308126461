/** @format */
import React from "react";
export const MinimizeIcon = ({ onClick, className }) => {
	return (
		<svg
			className={"popup-minimize-icon " + className}
			width="13"
			height="3"
			viewBox="0 0 16 3"
			fill="none"
			onClick={onClick}
		>
			<line
				strokeWidth="1.2"
				x1="0.656738"
				y1="0.72168"
				x2="16.6567"
				y2="0.72168"
				stroke="black"
			/>
		</svg>
	);
};
export const MaximizedIcon = ({ onClick, className }) => {
	return (
		<svg
			className={"popup-maximized-icon " + className}
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
		>
			<g opacity="0.88">
				<mask id="path-2-inside-1" fill="white">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M3 1H11V9H10V3C10 2.44772 9.55228 2 9 2H3V1ZM2 2V1C2 0.447715 2.44772 0 3 0H11C11.5523 0 12 0.447715 12 1V9C12 9.55228 11.5523 10 11 10H10V11C10 11.5523 9.55229 12 9 12H1C0.447715 12 0 11.5523 0 11V3C0 2.44772 0.447715 2 1 2H2Z"
					/>
				</mask>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M3 1H11V9H10V3C10 2.44772 9.55228 2 9 2H3V1ZM2 2V1C2 0.447715 2.44772 0 3 0H11C11.5523 0 12 0.447715 12 1V9C12 9.55228 11.5523 10 11 10H10V11C10 11.5523 9.55229 12 9 12H1C0.447715 12 0 11.5523 0 11V3C0 2.44772 0.447715 2 1 2H2Z"
					fill="white"
				/>
				<path
					d="M11 1H12V0H11V1ZM3 1V0H2V1H3ZM11 9V10H12V9H11ZM10 9H9V10H10V9ZM3 2H2V3H3V2ZM2 2V3H3V2H2ZM10 10V9H9V10H10ZM11 0H3V2H11V0ZM12 9V1H10V9H12ZM10 10H11V8H10V10ZM9 3V9H11V3H9ZM9 3H11C11 1.89543 10.1046 1 9 1V3ZM3 3H9V1H3V3ZM2 1V2H4V1H2ZM1 1V2H3V1H1ZM3 -1C1.89543 -1 1 -0.104569 1 1H3V1V-1ZM11 -1H3V1H11V-1ZM13 1C13 -0.10457 12.1046 -1 11 -1V1H13ZM13 9V1H11V9H13ZM11 11C12.1046 11 13 10.1046 13 9H11V11ZM10 11H11V9H10V11ZM9 10V11H11V10H9ZM9 11V13C10.1046 13 11 12.1046 11 11H9ZM9 11H1V13H9V11ZM1 11H1H-1C-1 12.1046 -0.104569 13 1 13V11ZM1 11V3H-1V11H1ZM1 3V3V1C-0.10457 1 -1 1.89543 -1 3H1ZM1 3H2V1H1V3Z"
					fill="black"
					mask="url(#path-2-inside-1)"
				/>
			</g>
		</svg>
	);
};
export const MaximizeIcon = ({ onClick, className }) => {
	return (
		<svg
			className={"gap-pointer popup-maximize-icon " + className}
			onClick={onClick}
			width="13"
			height="13"
			viewBox="0 0 13 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x=".5"
				y=".5"
				strokeWidth="1.2"
				width="11"
				height="11"
				rx="0"
				stroke="black"
			></rect>
		</svg>
	);
};
export const CloseIcon = ({ onClick, className }) => {
	return (
		<svg
			className={"popup-close-icon " + className}
			onClick={onClick}
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.97969 5.96697L11.8397 1.10697C11.9489 0.979437 12.006 0.815386 11.9995 0.647602C11.993 0.479818 11.9235 0.320659 11.8047 0.201929C11.686 0.0831994 11.5268 0.0136441 11.3591 0.00716337C11.1913 0.000682604 11.0272 0.0577536 10.8997 0.166971L6.03969 5.02697L1.1797 0.160304C1.05216 0.0510868 0.888111 -0.00598358 0.720327 0.00049719C0.552543 0.00697796 0.393384 0.0765326 0.274654 0.195262C0.155924 0.313992 0.086369 0.473151 0.0798883 0.640935C0.0734075 0.808719 0.130479 0.97277 0.239696 1.1003L5.0997 5.96697L0.233029 10.827C0.163241 10.8867 0.106561 10.9603 0.0665457 11.043C0.0265302 11.1257 0.00404311 11.2158 0.000496786 11.3076C-0.00304954 11.3994 0.0124211 11.491 0.0459375 11.5765C0.0794538 11.6621 0.130293 11.7398 0.195262 11.8047C0.260232 11.8697 0.33793 11.9205 0.423481 11.9541C0.509031 11.9876 0.600585 12.003 0.692398 11.9995C0.784211 11.996 0.874299 11.9735 0.957009 11.9335C1.03972 11.8934 1.11326 11.8368 1.17303 11.767L6.03969 6.90697L10.8997 11.767C11.0272 11.8762 11.1913 11.9333 11.3591 11.9268C11.5268 11.9203 11.686 11.8507 11.8047 11.732C11.9235 11.6133 11.993 11.4541 11.9995 11.2863C12.006 11.1186 11.9489 10.9545 11.8397 10.827L6.97969 5.96697Z"
				fill="black"
			/>
		</svg>
	);
};
