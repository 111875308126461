import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { P3DataGrid } from "../../components/data-grid/p3-data-grid";
import { P3RadioButton } from "../../components/form/form-item";
import { ScrollArea } from "../../components/p3-scrollBar";
import { P3InfoIcon, P3StatusBarStatus } from "../../components/status-bar/status-bar";
import { LOAD_NVR } from "../../graphql/queries";
import { SelectDropdown } from "../main-view";
import "./sizer-style.scss";

const groupData = (d) => {
    const merged = d.reduce((r, { product_class, product_class_display, product_class_description,...rest }) => {
        const key = `${product_class}-${product_class_display}-${product_class_description}`;
        r[key] = r[key] || { product_class, product_class_display, product_class_description, children: [] };
        r[key]["children"].push(rest)
        return r;
    }, {})
    const data = Object.values(merged).reverse();
    return data;
}

export function ChassisConfiguration(props) {
    const { bandWidth, diskSpace } = props;
    const { data } = useQuery(LOAD_NVR, {
        context: {
			headers: {
				"authorization": "Bearer " + props.keycloakToken,
				"Content-Type": "application/json"
			}
		},
        variables: {
            total_bandwidth_mbps: bandWidth, total_capacity: diskSpace
        }
    });
    useEffect(() => {
        if (data) {
            let sizeData = data.retrieve_recommended_nvrs || {};
            let nvr = groupData(JSON.parse(JSON.stringify(sizeData)), "product_class_display", "product_class");
            props.getNvr(nvr);

        }
    }, [data]);
    return (
        <ChassisPage {...props} />
    )
}
export class ChassisPage extends React.Component {
    static propTypes = {
        onClose: PropTypes.func,
        onStatusAlert: PropTypes.func
    };
    static defaultProps = {
        networkTypes: []
    }
    constructor(props) {
        super(props);
        this.state = {
            isProgress: false,
            isAlertPopup: false,
            nvr:this.props.nvr,
            nvrIndex:this.props.nvrIndex,
            smartNvrIndex:this.props.smartNvrIndex,
            selectedNvr: this.props.selectedNvr,
            networkTypes: this.props.networkTypes,
            selectedNetwork: this.props.selectedNetwork
        };
        this.customerPhoneErrorMessage = {
            required: "Fields are required",
            minLength: "Fields should be between 2 and 50 characters",
            maxLength: "Fields should be between 2 and 50 characters"
        }
    }

    componentDidMount(){
        this.setNvrItems();
    }

    setNvrItems = (setExistingNetwork=true) =>{
        this.state.nvr.map((item,index)=>{
            return item.children.map((child, ind)=>{
                let childIndex = (index === 0)?this.state.smartNvrIndex:this.state.nvrIndex;
                child.isActive = (ind === childIndex)
                if(this.state.selectedNvr === index && ind === childIndex){
                    let networkTypes = child.nics;
                    let selectedNetwork = (this.props.selectedNetwork === "" && setExistingNetwork) ?((child.nics && child.nics.length) ? child.nics[0].display:""):this.props.selectedNetwork;
                    this.setState({networkTypes, selectedNetwork},()=>this.props.updateValue({networkTypes, selectedNetwork}));
                }
                return child;
            })
        })
    }

    onDeviceChange = (obj, index, isNvr) => {
		let dp = isNvr?this.props.nvr[0].children:this.props.nvr[1].children;
		dp.map((item, ind) => {
			if (index === ind) {
				item.isActive = true;
			} else {
				item.isActive = false;
			}
			return item;
		});
	}

    getCols = (label = "default") => {
        return this.cols = [
            new P3ColumnData(
                " ",
                "DisplayTotalUsed"
            ).mixin({
                allowSort: false, allowFilter: false,
                width: "20%",
                labelFunction: (row, col, index) => (
                    <div className="row">
                        <P3RadioButton col={2} id={label} name={label} isChecked={row.isActive}/>
                        <div className="pl-3">
                            <label className={"pr-2 m-0 " + (row.recommendation_type === "Best Value" ? "recommend" : "")}>{row.recommendation_type}</label>
                            <div className="chassis-description chassis-model">{row.model}</div>
                        </div>
                    </div>
                )
            }),
            new P3ColumnData(
                "Configuration",
                "recommendation_details"
            ).mixin({
                allowSort: false, allowFilter: false, width: "25%", labelFunction: (row, col, index) => (
                    <div>
                        <div title={row.recommendation_details} className="chassis-description">{row.recommendation_details}</div>
                    </div>
                ), headerFunction: col => (
                    <div className="chassis-header">
                        {col.title}
                    </div>
                )
            }),
            new P3ColumnData(
                "Drive Description",
                "drive_description"
            ).mixin({
                allowSort: false, allowFilter: false, width: "25%", labelFunction: (row, col, index) => (
                    <div>
                        <div title={row.drive_description} className="chassis-description">{row.drive_description}</div>
                    </div>
                ), headerFunction: col => (
                    <div className="chassis-header">
                        {col.title}
                    </div>
                )
            }),
            new P3ColumnData(
                "Quantity",
                "num_nodes"
            ).mixin({
                allowSort: false, allowFilter: false, width: "15%", labelFunction: (row, col, index) => (
                    <div className="chassis-row">
                        {row.num_nodes}
                    </div>
                )
            }),
            new P3ColumnData(
                "Total Raw Capacity",
                "total_capacity_tb"
            ).mixin({
                allowSort: false, allowFilter: false, width: "15%", labelFunction: (row, col, index) => (
                    <div className="chassis-row">
                        {row.total_capacity_tb}
                    </div>
                )
            })
        ];
    }

    componentDidUpdate(prevProps, _prevState) {
        if(prevProps.selectedNvr !== this.props.selectedNvr){
            this.setState({selectedNvr:this.props.selectedNvr})
        }
        if(prevProps.nvr !== this.props.nvr){
            this.setState({nvr:this.props.nvr}, ()=>this.setNvrItems(false))
        }
        if(prevProps.networkTypes !== this.props.networkTypes){
            this.setState({networkTypes:this.props.networkTypes})
        }
        if(prevProps.selectedNetwork !== this.props.selectedNetwork){
            this.setState({selectedNetwork:this.props.selectedNetwork})
        }
        if(prevProps.nvrIndex !== this.props.nvrIndex){
            this.setState({nvrIndex:this.props.nvrIndex})
        }
        if(prevProps.smartNvrIndex !== this.props.smartNvrIndex){
            this.setState({smartNvrIndex:this.props.smartNvrIndex})
        }
    }

    onItemChange = (row, col, index, ind) => {
        if (row && !row.isActive) {
            this.selectedItem = row;
            let selectedNetwork = row && row.nics && row.nics.length?row.nics[0].display:""
            this.props.updateValue({"networkTypes":row.nics, selectedNetwork, [ind === 1?"nvrIndex":"smartNvrIndex"]:index});
            this.onDeviceChange(row, index, ind === 0);
        }
    }
    onCompChange = (value, name) => {
        this.props.updateValue({ [name]: value });
        //this.setState({ [name]: value });
    }

    setNvr = (ind) => {
        let networkIndex = ind === 0 ? this.state.nvrIndex : this.state.smartNvrIndex;
        let networkTypes = this.state.nvr[ind].children[networkIndex].nics;
        let selectedNetwork = (networkTypes && networkTypes.length)?networkTypes[0].display:this.state.selectedNetwork;
        this.props.updateValue({selectedNvr: ind, networkTypes, selectedNetwork});
    }

    showDesc = (index) =>{
        let description = this.state.nvr[index].product_class_description || ""
        this.props.openAlert(description,P3StatusBarStatus.INFO);
    }

    render() {
        let isNetworkDisabled = !this.state.networkTypes || !this.state.networkTypes.length;
        return (
            <ScrollArea
                    ref={input => (this.scrollArea = input)}
                    className="p3-scrollbar-area"
                    contentClassName={
                        " p3-scrollbar-content"
                    }
                    smoothScrolling={true}
                >
            <div className="w-100 h-100 col">
                {this.state.nvr.map((item,index) =>
                    <>
                        <div className="row pl-0">
                            <P3RadioButton col={0} containerClassName="text-left pl-2" id="test" name={item.product_class+"parent"} value={item.product_class+"parent"}
                                isChecked={this.props.selectedNvr === index} label={item.product_class_display} onChange={()=>this.setNvr(index)} />
                            <P3InfoIcon className="pl-2 info-icon" onClick={()=>this.showDesc(index)}/>
                        </div>
                        <div className={"p3-datagrid-main-container chassis-grid " + (this.state.selectedNvr === index ? "div-enable" : "div-disable")}>
                            <P3DataGrid
                                usePagination={false}
                                columns={this.getCols(item.product_class)}
                                selectable
                                itemRefrenceKey="recommendation_type"
                                tableHeaderClassName="w-100"
                                rowClassName="chassis-row"
                                allowColumnResize={false}
                                onSelect={(row,col,ind)=>this.onItemChange(row,col,ind, index)}
                                dataProvider={item.children}
                            />
                        </div>
                    </>)}
                <div className="col-12 p3-compute">
                    <div className="row  pl-0 col-5 pb-4">
                        <label className={"col-4  pt-1 customer-label "+(isNetworkDisabled?"comp-disable":"")}>Network Type</label>
                        <SelectDropdown className="dropdown col-8" name="selectedNetwork" disabled={isNetworkDisabled} dataProvider={this.state.networkTypes || []} valueField="display"
                            labelField="display" selectedValue={this.state.selectedNetwork} onChange={this.onCompChange} />
                    </div>
                </div>
            </div>
            </ScrollArea>
        );
    }
}

export class P3ColumnData {
    constructor(
        title,
        dataField,
        labelFunction = null,
        headerFunction = null,
        width = null,
        mixin = null
    ) {
        this.title = title;
        this.dataField = dataField;
        this.width = width;
        this.labelFunction = labelFunction;
        this.headerFunction = headerFunction;
        this.allowFilter = true;
        this.filterFunction = null;
        this.filterText = "";
        this.allowSort = true;
        this.sortCompareFunction = null;
        this.sortFlipper = 0;
        this.showTooltip = false;
        this.allowCopyToClipboard = false;

        if (mixin !== null) {
            this.mixin(mixin);
        }
    }

    mixin = props => {
        if (props !== null) {
            let keys = Object.keys(props);
            keys.forEach(key => {
                this[key] = props[key];
            });
        }
        return this;
    };
}
export default ChassisConfiguration