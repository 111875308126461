/** @format */
import React from "react";

export class P3CSSSpinner extends React.Component {
	static defaultProps = {
		className: "",
		style: null
	};

	constructor(props) {
		super(props);

		this.state = {
			percentage: this.props.percentage,
			showPercentage: this.props.showPercentage
		};
	}

	componentDidUpdate(prevProps, _prevState) {
		if (prevProps.percentage !== this.props.percentage) {
			this.setState({ percentage: this.props.percentage })
		}
		if (prevProps.showPercentage !== this.props.showPercentage) {
			this.setState({ showPercentage: this.props.showPercentage })
		}
	}

	render() {
		return (
			<div
				className={"spinner spinner-xsmall " + this.props.className}
				style={this.props.style}
			></div>
		);
	}
}

/*
			<div
				className={"p3-progress-circle " + (this.props.className || '')}>
				<div className="p3-progress-circle1 p3-progress-child" />
				<div className="p3-progress-circle2 p3-progress-child" />
				<div className="p3-progress-circle3 p3-progress-child" />
				<div className="p3-progress-circle4 p3-progress-child" />
				<div className="p3-progress-circle5 p3-progress-child" />
				<div className="p3-progress-circle6 p3-progress-child" />
				<div className="p3-progress-circle7 p3-progress-child" />
				<div className="p3-progress-circle8 p3-progress-child" />
				<div className="p3-progress-circle9 p3-progress-child" />
				<div className="p3-progress-circle10 p3-progress-child" />
				<div className="p3-progress-circle11 p3-progress-child" />
				<div className="p3-progress-circle12 p3-progress-child" />
				{ 
					this.state.showPercentage && (<label className="p3-progress-bar-percenatge" 
						style={{display: "inline-block" }} >
							{this.state.percentage}%
						</label>
					)
				}
		  </div>*/
