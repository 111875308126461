/** @format */
import PropTypes from "prop-types";
import React from "react";
import { ScrollArea } from "../p3-scrollBar";
import "./p3-data-grid.scss";

export class P3ColumnData {
	constructor(
		title,
		dataField,
		labelFunction = null,
		headerFunction = null,
		width = null,
		mixin = null
	) {
		this.title = title;
		this.dataField = dataField;
		this.width = width;
		this.labelFunction = labelFunction;
		this.headerFunction = headerFunction;
		this.allowFilter = true;
		this.filterFunction = null;
		this.filterText = "";
		this.allowSort = true;
		this.sortCompareFunction = null;
		this.sortFlipper = 0;
		this.showTooltip = false;
		this.allowCopyToClipboard = false;

		if (mixin !== null) {
			this.mixin(mixin);
		}
	}

	mixin = props => {
		if (props !== null) {
			let keys = Object.keys(props);
			keys.forEach(key => {
				this[key] = props[key];
			});
		}
		return this;
	};
}

const MIN_COL_SIZE = 80;
export class P3ColumnHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isFilterOpen: false,
			filterText: this.props.data.filterText
		};
		this.filterPopoverId = {};
		this.setFilterId();
		this.updateData(this.props);
	}

	updateData = props => {
		/* Based on persistColumnResize value, alter the original data(refers col.width) or cloned data */
		if (!props.allowColumnResize || props.persistColumnResize) {
			this.data = props.data;
		} else {
			/* colsWithNewReference is the cloned data of this.props.columns. 
				If column resize data need not to be persisted, 
				then we should not alter the original width present in column object, instead manipulate width in this cloned data 
			*/
			this.data = props.colsWithNewReference[props.index];
		}
	};

	componentDidMount() {
		this.props.allowColumnResize &&
			document.addEventListener("mouseup", this.onDocumentMouseUp);
	}

	componentWillUnmount() {
		this.props.allowColumnResize &&
			document.removeEventListener("mouseup", this.onDocumentMouseUp);
		//free the element reference(ref) variable
		this.thElement = null;
	}

	onFilterClick = e => {
		e.stopPropagation(); // To Stop Sorting
	};

	onFilterToggle = isOpen => {
		isOpen && this.filterInput.focus();
		this.setState({
			isFilterOpen: isOpen
		});
	};

	onApplyFilter = e => {
		const col = this.props.data;
		col.filterText = this.filterInput.value;
		this.props.onApplyFilter(col, col.filterText);
		this.setState({ isFilterOpen: false });
	};

	onClerFilter = e => {
		const col = this.props.data;
		col.filterText = "";
		this.setState({ filterText: "" }, () => {
			this.filterInput.focus();
			this.props.onApplyFilter(col, "");
		});
	};

	onFilterInputChange = e => {
		const col = this.props.data;
		col.filterText = this.filterInput.value;
		this.setState({ filterText: this.filterInput.value }, () => {
			this.props.onApplyFilter(col, col.filterText);
		});
	};

	onFilterInputKeyDown = e => {
		switch (e.key.toLowerCase()) {
			case "enter":
				this.onApplyFilter(e);
				break;
			case "escape":
				this.setState({ isFilterOpen: false });
				break;
			default:
		}
	};

	componentWillReceiveProps(nextProps) {
		if (
			this.props.colsWithNewReference &&
			(this.props.colsWithNewReference[this.props.index] !==
				nextProps.colsWithNewReference[nextProps.index] ||
				this.props.allowColumnResize !== nextProps.allowColumnResize ||
				this.props.persistColumnResize !==
					nextProps.persistColumnResize)
		) {
			this.updateData(nextProps);
		}
		this.setState({
			filterText: nextProps.data.filterText
		});
	}
	setFilterId() {
		const col = this.props.data;

		let data = /*col.title + '_' + */ col.dataField + Math.random();
		data = data.replace(/\s|\./g, "_");
		this.filterPopoverId = data;
	}

	/** 
		This funciton gets executed upon mouseover of resize handler element.
		@param {HTMLElement} target - Its the element obtained from event object.
		Both resizeHadler(thead and tbody) elements won't be visible initially, upon mouseover we will make them visible.
		For tBody's resizeHandler element, we need to set 'left' dynamically based on the left position of target(thead resize handler element).
	*/
	onResizeHandlerMouseOver = ({ target }) => {
		this.tBodyResizeHandlerElement = this.props.tBodyResizeHandlerElement.current;
		if (this.tBodyResizeHandlerElement.style.display !== "block") {
			this.parentLeftPosition = this.props.tBodyElement.getBoundingClientRect().left;
			const targetLeftPosition = target.getBoundingClientRect().left;
			target.style.opacity = 1;
			this.tBodyResizeHandlerElement.style.display = "block";
			this.tBodyResizeHandlerElement.style.left =
				targetLeftPosition - this.parentLeftPosition + "px";
		}
	};

	/** 
		This funciton gets executed upon mouseover of resize handler element.
		@param {HTMLElement} target - Its the element obtained from event object.
		Hide the resize handler elements on mouse out.
	*/
	onResizeHandlerMouseOut = ({ target }) => {
		if (!this.props.resizeHandlerClicked) {
			this.hideResizeHandlerElement(target);
		}
	};

	/* 
		On mousedown, get the offset left of the thElement 
		offsetLeft = element width - mouse position while clicking;
	*/
	onResizeHandlerMouseDown = ({ target, pageX }) => {
		this.props.enableOverlay(); // To enable overlay element in body
		this.resizeHandlerClicked = true;
		this.resizeHandleElement = target;
		document.addEventListener("mousemove", this.onColumnDrag);
		this.thElementOffset = this.thElement.offsetWidth - pageX;
	};

	onResizeHandlerClick = e => {
		/* to avoid sorting on clicking the resize handler */
		e.stopPropagation();
	};

	/*
		This function get called while clicking and dragging the resize handler(on mouse move)
		Calculates the new width of thElement. 
		newWidth = offsetLeft of element + current mouse position
	*/
	onColumnDrag = e => {
		// console.log("data grid column resized");
		let startOffset = this.thElementOffset;
		if (startOffset) {
			let resizeHandlerPosition = startOffset + e.pageX;
			/* MIN_COL_SIZE is the minimum width upto which minimum resize is possible */
			if (resizeHandlerPosition >= MIN_COL_SIZE && this.data) {
				this.data.width = resizeHandlerPosition + "px";
				/* resize handler left position will always be 5px less than the width of the parent element */
				this.resizeHandleElement.style.left =
					resizeHandlerPosition - 5 + "px"; //5 is the width of resize handler element
				//on resize handler element drag, set the left position of resize handler element to tBodyResizeHandlerElement, so that it moves along with the resize handler element.
				let targetLeft = this.resizeHandleElement.getBoundingClientRect()
					.left;
				this.tBodyResizeHandlerElement.style.left =
					targetLeft - this.parentLeftPosition + "px";
			}
		}
	};

	/* 
		This function is executed while doing mouse up on document.
		If there is change in element's(th element) old width and new width, sends the difference to the parent component, 
		so that increased width is added to the existing width of table and Colgroup updated the width accordingly.
	*/
	onDocumentMouseUp = ({ target }) => {
		if (this.resizeHandlerClicked) {
			const oldWidth = parseFloat(this.thElement.offsetWidth);
			if (this.data) {
				const newWidth = parseFloat(this.data.width);
				this.thElementOffset = null;
				this.widthDelta = newWidth - oldWidth;
				this.props.updateColumnWidth(this.widthDelta);
			}
			//on mouseup hide the resize handler element
			if (target !== this.resizeHandleElement) {
				this.hideResizeHandlerElement(this.resizeHandleElement);
			}
			this.resizeHandleElement = null;
			document.removeEventListener("mousemove", this.onColumnDrag);
			this.resizeHandlerClicked = false;
		}
	};

	hideResizeHandlerElement = resizeHandleElement => {
		this.tBodyResizeHandlerElement.style.display = "none";
		resizeHandleElement.style.left = "auto";
		resizeHandleElement.style.opacity = 0;
	};

	setThElementRef = el => {
		this.thElement = el;
	};

	render() {
		const col = this.props.data;
		const headerFunction =
			col.headerFunction ||
			function(col) {
				return col.title;
			};
		let title = col.headerFunction ? "" : col.title;
		let headerSizeAdjust = 2;

		col.allowSort && (headerSizeAdjust += 14); // sort icon width(14px)
		col.allowFilter && (headerSizeAdjust += 16); // Filter icon width(16px)

		let sortIcons = {
			
		};

		return (
			<th
				className={col.allowSort ? "p3-show-pointer" : "p3-show-arrow"}
				onClick={this.props.onClick}
				ref={this.setThElementRef}
			>
				<div
					className={"p3-data-grid-header-title "+this.props.tableHeaderClassName}
					style={{
						maxWidth: "calc(100% - " + headerSizeAdjust + "px)"
					}}
					data-auto-id={`Header-ID-${col.title}`}
					title={title}
				>
					{headerFunction(col)}
				</div>
				
				{col.allowSort && sortIcons[col.sortFlipper]}
				{this.props.allowColumnResize && (
					<div
						className="p3-resize-handle"
						onClick={this.onResizeHandlerClick}
						onMouseOver={this.onResizeHandlerMouseOver}
						onMouseOut={this.onResizeHandlerMouseOut}
						onMouseDown={this.onResizeHandlerMouseDown}
					></div>
				)}
			</th>
		);
	}
}

const ExpandedRow = ({ colSpan, children }) => {
	return (
		<tr>
			<td colSpan={colSpan}>{children}</td>
		</tr>
	);
};

const Expander = ({ onClick, expanded }) => (
	<span className="expander-holder" onClick={onClick}>
		<span className={"expander " + (expanded ? "down" : "")} />
	</span>
);

export class P3DataGridCell extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			iconClass: "p3-copy-icon",
			hideCopyIconToolTip: false
		};
	}

	onCopyClipboard = colValue => {
		if (document.selection) {
			// IE
			let range = document.body.createTextRange(); // create the range to select the text
			range.moveToElementText(colValue); // set the target text to select
			range.select(); // select the targeted text
		} else if (window.getSelection) {
			let range = document.createRange(); // create the range to select the text
			range.selectNode(colValue); // set the target text to select
			window.getSelection().removeAllRanges(); // remove all the previous selection
			window.getSelection().addRange(range); // select the targeted text
		}
		document.execCommand("copy"); // copy the selected text
		this.setState(
			{ iconClass: "p3-copied-icon", hideCopyIconToolTip: true },
			() => setTimeout(this.resetIcon, 2000)
		);
	};

	resetIcon = () => {
		this.setState({
			iconClass: "p3-copy-icon",
			hideCopyIconToolTip: false
		});
	};

	onExpanderClick = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.onExpanderClick &&
			this.props.onExpanderClick(this.props.data, this.props.column);
	};

	render() {
		const col = this.props.column;
		const data = this.props.data;
		//console.log("row Index   ",this);
		//const rowInd = 
		const onCellClick =
			this.props.onCellClick ||
			function() {
				/*console.log('cell Clicked') */
			};
		const onCopyClipboard = col.allowCopyToClipboard
			? this.onCopyClipboard
			: function() {
					/*console.log('cell Clicked') */
			  };
		const copyToClipboardTooltip =
			col.copyToClipboardTooltip || "Copy To Clipboard";
		const labelFunction =
			col.labelFunction ||
			function(row, col) {
				return data[col.dataField];
			};
		return (
			<td
				className={
					"p3-dg-td p3-wrapword" +
					(col.colClassName ? " " + col.colClassName : "")
				}
				onClick={e => onCellClick(data, col)}
			>
				<div
					data-rh={col.showTooltip ? labelFunction(data, col) : null}
					data-rh-at="top"
				>
					<div
						className={
							col.allowCopyToClipboard ? "p3-col-value" : ""
						}
						ref={div => (this.colValue = div)}
					>
						{this.props.showExpander && (
							<Expander
								onClick={this.onExpanderClick}
								expanded={this.props.expanded}
							/>
						)}
						{labelFunction(data, col)}
					</div>
					{col.allowCopyToClipboard && (
						<div
							data-rh={
								this.state.hideCopyIconToolTip
									? null
									: copyToClipboardTooltip
							}
							data-rh-at="left"
							onClick={e => onCopyClipboard(this.colValue)}
							className={this.state.iconClass}
						/>
					)}
				</div>
				{data.disabled && <div className="p3-parent-blocker" />}
			</td>
		);
	}
}

export class P3DataGridRow extends React.PureComponent {
	onColClick(row, col, colIndex) {
		const onRowSelect =
			this.props.onRowSelect ||
			function(row, col) {
				//console.log('row clicked', row, col);
			};
		onRowSelect(row, col, colIndex);
	}

	render() {
		const cols = this.props.columns;
		const row = this.props.data;
		const activeClass = this.props.activeClass || "";
		const defaultColWidth = 100 / cols.length;
		let classNames = [this.props.className, activeClass];
		if (row.disabled) {
			classNames.push("p3-dg-disabled-row");
		}
		if (row.readOnly) {
			classNames.push("p3-grid-readOnly");
		}
		if (this.props.expanded) {
			classNames.push("p3-expanded-row");
		}
		return (
			<tr className={classNames.join(" ")}>
				{cols.map((col, index) => (
					<P3DataGridCell
						showExpander={this.props.expendable && index === 0}
						expanded={this.props.expanded}
						onExpanderClick={this.props.onExpanderClick}
						key={index}
						data={row}
						column={cols[index]}
						defaultColWidth={defaultColWidth}
						onCellClick={(row, col) =>
							this.onColClick(row, col, index)
						}
					/>
				))}
			</tr>
		);
	}
}

export class P3Col extends React.Component {
	/* 
		Calculate width in pixel, so that width of all columns can be added together and when a column is resized. 
		The sum of all col widths is set as width for table container(scroller) element.
	*/
	calculateWidthInPixel = (colWidth, col) => {
		let widthInPixel = Math.round(
			(this.props.dataTableWidth * parseFloat(colWidth)) / 100
		);
		col.width = widthInPixel;
		return widthInPixel;
	};
	render() {
		const col = this.props.data;
		const colWidth = this.props.defaultColWidth + "%";
		let columnWidth = col.width || colWidth;
		if (
			this.props.allowColumnResize &&
			this.props.dataTableWidth !== "auto" &&
			typeof columnWidth === "string"
		)
			columnWidth = columnWidth.includes("%")
				? this.calculateWidthInPixel(columnWidth, col)
				: columnWidth;
		return <col style={{ width: columnWidth }} />;
	}
}

/* 
	This colgroup element is used to apply formatting options to td and th elements. It helps to maintain fixed table layout.
	The same element is resued for both tables(table in header part and table in body part).
*/
const ColGroup = ({
	allowColumnResize,
	cols,
	defaultColWidth,
	dataTableWidth,
	setColGroupRef
}) => {
	return (
		<colgroup ref={setColGroupRef}>
			{cols.map((col, index) => (
				<P3Col
					key={index}
					data={col}
					defaultColWidth={defaultColWidth}
					dataTableWidth={dataTableWidth}
					allowColumnResize={allowColumnResize}
				/>
			))}
		</colgroup>
	);
};

export class P3DataGrid extends React.Component {
	static propTypes = {
		columns: PropTypes.arrayOf(PropTypes.instanceOf(P3ColumnData)), //.arrayOf(P3ColumnData).isRequired,
		dataProvider: PropTypes.arrayOf(Object),

		selectable: PropTypes.bool,
		onSelect: PropTypes.func,
		itemRefrenceKey: PropTypes.string,
		isResetScroll: PropTypes.bool,
		/**
		 * determines whether table should render all the records or so page by page (based on pageSize and maxRowCount props)
		 */
		usePagination: PropTypes.bool,
		/**
		 * which is used to determine how many rows going to render at a time when we have a large set of rows
		 */
		maxRowCount: PropTypes.number,
		/**
		 * which is used to determain how many records we will display per pages (if we set usePagingation=true other wise it has no effect).
		 */
		pageSize: PropTypes.number,
		/**
		 * showPageSizeFilter will determin whether we need to show the page size dropdown
		 */
		showPageSizeFilter: PropTypes.bool,

		/**
		 * options that has to be listed in the page size dropdown
		 */
		pageSizes: PropTypes.arrayOf(PropTypes.number),

		/**
		 *
		 */
		isScrollable: PropTypes.bool,
		onScroll: PropTypes.func,
		showRecordCount: PropTypes.bool,
		disabled: PropTypes.bool
	};

	static defaultProps = {
		selectable: false,
		tableHolderHeightClassName: "",
		tableHeaderClassName: "",
		isResetScroll: false,
		showNoDataAlert: true,
		usePagination: true,
		maxRowCount: 15,
		pageSize: 15,
		pageSizes: [15, 30, 50],
		showPageSizeFilter: false,
		currentPage: 1,
		scrollIndex: 0,
		disabled: false,
		//selectedIndex: -1,
		selectedItem: null,
		isScrollable: true,
		showRecordCount: false,
		itemRefrenceKey: "uuid",
		"data-auto-id": "grid-table-holder",
		allowColumnResize: true,
		persistColumnResize: false //Enable this option, if column resize has to be persited(updated column width should be maintained) while switching tabs.
	};

	constructor(props) {
		super(props);
		let dataProvider = this.applyFilteringAndSorting(props);
		let selectedItem = this.getItemByRefKey(
			props.dataProvider,
			props.selectedItem,
			props.itemRefrenceKey
		);
		//flag to disable pointer events on column header (sorting & filtering) when dataprovider is empty initially
		let isSourceDataEmpty = props.hasOwnProperty("isSourceDataEmpty")
			? props.isSourceDataEmpty
			: props.dataProvider && props.dataProvider.length
			? false
			: true;
		//flag to decide whether filtered data provider has entries to show (will be true if initially it is rendered with empty data provider)
		let isFilteredDataHaveEntries = props.hasOwnProperty(
			"isFilteredDataHaveEntries"
		)
			? props.isFilteredDataHaveEntries
			: isSourceDataEmpty
			? true
			: dataProvider.length
			? true
			: false;
		this.state = {
			selectedItem,
			dataProvider,
			isSourceDataEmpty,
			selectable: props.selectable,
			isResetScroll: props.isResetScroll,
			usePagination: props.usePagination,
			maxRowCount: props.maxRowCount,
			pageSize: props.pageSize,
			pageSizes: props.pageSizes,
			showPageSizeFilter: props.showPageSizeFilter,
			currentPage: props.currentPage,
			displayItems: [],
			scrollIndex: props.scrollIndex,
			disabled: props.disabled,
			isScrollable: props.isScrollable,
			showRecordCount: props.showRecordCount,
			isFilteredDataHaveEntries,
			expandedRowIndex: -1,
			dataTableContainerWidth: props.allowColumnResize ? "auto" : "100%",
			dataTableWidth: "auto"
		};
		this.lazyCount = 10;
		this.isResized = true;
		this.onSelect = this.onSelect.bind(this);
		this.onHeaderClick = this.onHeaderClick.bind(this);
		if (this.props.allowColumnResize) {
			this.resizeHandlerClicked = false; //This Flag is used to prevent resize handler element from hiding while dragging in process. In mouse leave function we will make use of this flag.
			this.tBodyResizeHandlerElement = React.createRef();
			if (
				typeof this.props.columns === "object" &&
				Object.entries(this.props.columns).length !== 0
			) {
				/* 
					Deep clone of columns array is taken. This is needed if persistColumnResize option is set to false.
					Original columns array won't be polluted. Instead changes will be made only to this cloned array. 
				*/
				this.colsWithNewReference = JSON.parse(
					JSON.stringify(this.props.columns)
				);
			} else {
				this.colsWithNewReference = [];
			}
		}
	}

	//if the passed column is empty are no column is passed then dont show pagination/record count
	componentDidMount() {
		if (!this.props.columns || this.props.columns.length === 0) {
			this.setState({
				usePagination: false,
				showRecordCount: false
			});
		}

		//used to resize the datagrid on popup maximize and minimize
		window.addEventListener("optimizedResize", this.window_onResize);
		this.props.allowColumnResize && this.calculateDataTableWidth();
		this.resetDisplayItems();
	}

	window_onResize = () => {
		this.isResized = true;
		this.refreshDateTableLayout();
	};

	refreshDateTableLayout = () => {
		// Updates 'this.colsWithNewReference'with the original width available in 'this.props.columns'
		this.colsWithNewReference = JSON.parse(
			JSON.stringify(this.props.columns)
		);
		// Based on the original width, datatable layout will reset as like initial render.
		this.calculateDataTableWidth();
	};

	/* 
		Once component is mounted, we need to send the table width to ColGroup Component, 
		which in turn sets width to <col> element in pixel, based on datatable width and percentage assigned to each column. 
	*/
	calculateDataTableWidth = () => {
		if (!this.tHeadHolderElement) {
			return;
		}
		//initialTableWidth is the width of Table used in Theader section.
		let initialTableWidth = this.tHeadHolderElement.offsetWidth;
		if (!this.initialDataTableWidth) {
			this.initialDataTableWidth = initialTableWidth;
		}
		//If persistColumnResize option is enabled, we need to set the sum of widths of all <col> element to TableContainer(scroller) element.
		const cols = this.props.columns || [];
		if (cols.length) {
			//map all columns and convert width to pixel
			const dataTableContainerWidth = cols
				.map((col, i) => {
					let columnWidth = col.width;
					// if column length is 0 we are setting the default width as 0
					const defaultColWidth = 100 / cols.length + "%";
					columnWidth = columnWidth || defaultColWidth;
					if (
						typeof columnWidth === "string" &&
						!columnWidth.includes("%")
					)
						return parseFloat(col.width);
					else {
						let widthInPixel =
							(initialTableWidth * parseFloat(columnWidth)) / 100;
						return widthInPixel;
					}
				})
				.reduce((sum, colWidth) => {
					//sum of all columns width is calculated
					return sum + colWidth;
				});
			//set the calculated width to scroller element
			this.setState({
				dataTableContainerWidth,
				dataTableWidth: initialTableWidth
			});
		}
	};

	componentWillUnmount() {
		//free the element reference(ref) variable
		this.colGroupElement = this.tHeadHolderElement = null;
		window.removeEventListener("optimizedResize", this.window_onResize);
		
	}

	onSelect(row, col, rowIndex, colIndex) {
		if (this.state.selectable) {
			this.setState({ selectedItem: row });
			this.notifyOnSelect(
				row,
				col,
				rowIndex,
				colIndex,
				this.state.dataProvider
			);
		}
	}

	onExpanderClick = (row, col) => {
		this.toggleRowExpander(row);
	};

	toggleRowExpander = row => {
		if (this.state.expandedRow === row) {
			this.setState({
				expandedRow: null
			});
		} else {
			this.setState({
				expandedRow: row
			});
		}
	};

	notifyOnSelect = (row, col, rowIndex, colIndex, dataProvider = null) => {
		this.props.onSelect &&
			this.props.onSelect(row, col, rowIndex, colIndex, dataProvider);
	};

	applyFilteringAndSorting = props => {
		let cols = props.columns;
		let dp = props.dataProvider || [];
		if (cols && dp.length) {
			dp = this.applyAllFilter(dp, cols);
			let sortedCol = null;
			cols.forEach(col => {
				if (col.sortFlipper !== 0) {
					sortedCol = col;
				}
			});
			if (sortedCol) {
				sortedCol.sortFlipper *= -1;
				dp = this.sortDataProvider(sortedCol, dp);
				sortedCol.sortFlipper *= -1;
			}
		}
		return dp;
	};

	componentWillReceiveProps(nextProps) {
		let selectedItem = this.state.selectedItem;
		//let selectedIndex = (isNaN(this.state.selectedIndex) ? -1 : this.state.selectedIndex);
		let dp = this.state.dataProvider || [];
		let needToNotifyChange = false;
		if (nextProps.selectable !== this.props.selectable) {
			this.setState({ selectable: nextProps.selectable });
		}
		if (nextProps.pageSize !== this.props.pageSize) {
			this.setState({ pageSize: nextProps.pageSize });
		}

		if (nextProps.currentPage !== this.props.currentPage) {
			this.setState({ currentPage: nextProps.currentPage });
		}
		if (nextProps.usePagination !== this.props.usePagination) {
			this.setState({ usePagination: nextProps.usePagination });
		}
		if (nextProps.maxRowCount !== this.props.maxRowCount) {
			this.setState({ maxRowCount: nextProps.maxRowCount });
		}
		if (nextProps.pageSizes !== this.props.pageSizes) {
			this.setState({ pageSizes: nextProps.pageSizes });
		}
		if (nextProps.showPageSizeFilter !== this.props.showPageSizeFilter) {
			this.setState({ showPageSizeFilter: nextProps.showPageSizeFilter });
		}

		if (nextProps.dataProvider !== this.props.dataProvider) {
			dp = this.applyFilteringAndSorting(nextProps);
			let isSourceDataEmpty =
				nextProps.dataProvider && nextProps.dataProvider.length
					? false
					: true;
			let newState = {
				dataProvider: dp,
				isSourceDataEmpty,
				displayItems: [],
				scrollIndex: 0,
				isFilteredDataHaveEntries: isSourceDataEmpty
					? true
					: dp.length
					? true
					: false
			};

			// if props have been passed from the parent then discard the calculated flags
			if (nextProps.hasOwnProperty("isFilteredDataHaveEntries")) {
				delete newState.isFilteredDataHaveEntries;
			}
			if (nextProps.hasOwnProperty("isSourceDataEmpty")) {
				delete newState.isSourceDataEmpty;
			}

			this.setState(newState, this.getLazyLoadingItems);
		}

		if (
			nextProps.hasOwnProperty("isFilteredDataHaveEntries") &&
			nextProps.isFilteredDataHaveEntries !==
				this.props.isFilteredDataHaveEntries
		) {
			this.setState({
				isFilteredDataHaveEntries: nextProps.isFilteredDataHaveEntries
			});
		}

		if (
			nextProps.hasOwnProperty("isSourceDataEmpty") &&
			nextProps.isSourceDataEmpty !== this.props.isSourceDataEmpty
		) {
			this.setState({ isSourceDataEmpty: nextProps.isSourceDataEmpty });
		}

		if (
			nextProps.selectable &&
			nextProps.hasOwnProperty("selectedItem") &&
			nextProps.selectedItem !== selectedItem
		) {
			selectedItem = this.getItemByRefKey(
				dp,
				nextProps.selectedItem,
				nextProps.itemRefrenceKey
			);

			if (
				this.state.selectedItem &&
				selectedItem &&
				this.state.selectedItem[nextProps.itemRefrenceKey] !==
					selectedItem[nextProps.itemRefrenceKey]
			) {
				// state already have a selected item, and it is NOT equal to new seletedItem
				needToNotifyChange = true;
				this.setState({ selectedItem });
			} else if (
				(!this.state.selectedItem && selectedItem) ||
				(this.state.selectedItem && !selectedItem)
			) {
				// 1. state does not have a selected item. but nextProp have a valid selectedItem
				// 2. state has a selectedItem. but new dataProvider does not have that item
				// TODO :: do we need to notify for 2nd case ???
				// Maybe YES. when we allow to deselect an item, we may need to do so.
				needToNotifyChange = true;
				this.setState({ selectedItem });
			} else {
				// state's and nextProp's selectedItem's are same.
			}
		}

		if (nextProps.disabled !== this.props.disabled) {
			this.setState({ disabled: nextProps.disabled });
		}

		if (nextProps.showRecordCount !== this.props.showRecordCount) {
			this.setState({
				showRecordCount: nextProps.showRecordCount
			});
		}

		if (!this.state.dataTableWidth) {
			this.refreshDateTableLayout();
		}

		this.setState({}, () => {
			needToNotifyChange &&
				this.notifyOnSelect(
					selectedItem,
					null,
					dp.indexOf(selectedItem),
					-1,
					dp
				);
		});
	}

	getItemByRefKey = (sourceArray, item, refKey = "uuid") => {
		if (sourceArray && item) {
			let filteredItems = sourceArray.filter(
				data => data[refKey] === item[refKey]
			);
			if (filteredItems.length > 0) {
				return filteredItems[0];
			}
		}
		return null;
	}

	// do sorting on the filtered dataprovider (if applied)
	onHeaderClick(currentCol) {
		let cols = this.props.columns;
		cols.forEach((col, index) => {
			if (col !== currentCol) {
				col.sortFlipper = 0;
			}
		});
		if (currentCol.sortFlipper === 0) {
			currentCol.sortFlipper = 1;
		}

		let dp = this.sortDataProvider(currentCol, this.state.dataProvider);
		currentCol.sortFlipper *= -1;
		this.setState(
			{ dataProvider: [...dp], currentPage: 1, selectedItem: null },
			() => {
				this.resetDisplayItems();
				this.notifyOnSelect(null, null, -1, -1, dp);
			}
		);
	}

	sortDataProvider = (currentCol, dp) => {
		if (!dp) {
			// when are we going to end up here?? INVESTIGATE!!!!
			return dp;
		}
		if (typeof currentCol.sortCompareFunction === "function") {
			dp.sort(
				(a, b) =>
					currentCol.sortCompareFunction(a, b, currentCol) *
					currentCol.sortFlipper
			);
		} else {
			let key = currentCol.dataField;
			dp.sort((a, b) => {
				let a1 =
					a && a.hasOwnProperty(key) && a[key] !== null ? a[key] : "";
				let b1 =
					b && b.hasOwnProperty(key) && b[key] !== null ? b[key] : "";
				if (currentCol.labelFunction !== null) {
					// a is not null AND labelFunction DOES NOT return ReactComponent (which is an object)
					a &&
						typeof currentCol.labelFunction(a, currentCol) !==
							"object" &&
						(a1 = currentCol.labelFunction(a, currentCol));
					b &&
						typeof currentCol.labelFunction(b, currentCol) !==
							"object" &&
						(b1 = currentCol.labelFunction(b, currentCol));
				}
				let isNumeric = !isNaN(a1) && !isNaN(b1);
				if (isNumeric) {
					return (a1 - b1) * currentCol.sortFlipper;
				}
				return a1.toString().localeCompare(b1) * currentCol.sortFlipper;
			});
		}
		return dp;
	};

	onApplyFilter = (col, filterText) => {
		let dp =
			this.props.dataProvider &&
			this.applyAllFilter(this.props.dataProvider, this.props.columns);
		//if data provider has entries then dont show the no records alert
		this.setState(
			{
				dataProvider: dp,
				selectedItem: null,
				isFilteredDataHaveEntries: dp && dp.length ? true : false
			},
			() => {
				this.notifyOnSelect(null, null, -1, -1, dp);
				this.resetDisplayItems();
			}
		);
	};

	applyAllFilter = (dp, cols) => {
		cols.forEach(col => {
			if (col.filterText && col.filterText.length > 0) {
				dp = this.applyColumnFilter(dp, col);
			}
		});
		return dp;
	};

	applyColumnFilter = (dp, col) => {
		let filterText = col.filterText.toLowerCase();
		if (col.allowFilter) {
			dp = dp.filter((data, index, array) => {
				if (col.filterFunction !== null) {
					return col.filterFunction(
						filterText,
						data,
						index,
						array,
						col
					);
				}
				return (
					(data[col.dataField] + "")
						.toLowerCase()
						.indexOf(filterText) >= 0
				);
			});
		}
		return dp;
	};

	onPageChange = (currentPage, totalPages) => {
		this.resetScrollPositionOnPageChange = true;
		let prevPage = this.state.currentPage;
		this.setState({ currentPage: currentPage });
		this.props.onPageChange &&
			this.props.onPageChange(prevPage, currentPage, totalPages); // first row on the current page
	};
	componentDidUpdate() {
		if (this.state.isResetScroll) {
			this.tableBody.scrollTop = 0;
		}
		if (this.isResized) {
			const [firstChild] = this.tableContainer.children;
			if (this.props.scrollUpdate && firstChild) {
				var visibleCount = Math.round(
					this.tableBody.clientHeight / firstChild.clientHeight
				);
				this.isResized = false;
				if (
					this.lazyCount !==
					Math.round(visibleCount + visibleCount / 4)
				) {
					this.lazyCount = Math.round(
						visibleCount + visibleCount / 4
					);
					this.resetDisplayItems();
				}
			}
		}
	}
	onScroll = value => {
		if (value.hasOwnProperty("leftPosition")) {
			this.tHeadHolderElement.scrollLeft = value.leftPosition || 0;
		}
		this.props.onScroll && this.props.onScroll(value);
		if (this.props.scrollUpdate) {
			this.onScrollUpdate(value);
		}
	};

	// Updates the grid data after the scrolling to 80 percent.
	//this.lazyCount - number of updated items on every scroll.
	//scrollIndex - scroll position based on datagrid items.
	onScrollUpdate = (event, scrollArea) => {
		let scrollPercentage =
			(100 * event.topPosition) /
			(event.realHeight - event.containerHeight); // calculation for scroll position in percentage
		if (
			scrollPercentage >= 80 &&
			this.state.scrollIndex < this.state.dataProvider.length
		) {
			//update data if scroll reaches 80 and above
			this.setState(
				{ scrollIndex: this.state.scrollIndex + this.lazyCount },
				this.getLazyLoadingItems
			);
		}
	};

	//displayItems - used as the dataprovider on scrollupdate props enabled.
	//scrollupdate - used to enable lazy loading
	getLazyLoadingItems = () => {
		if (this.props.scrollUpdate) {
			let displayItems = this.state.displayItems;
			let dp = this.state.dataProvider.slice(0); // Clones the original dataprovider to avoid data deletion.
			displayItems = displayItems.concat(
				dp.splice(this.state.scrollIndex, this.lazyCount)
			);
			this.setState({ displayItems });
		}
	};

	resetDisplayItems = () => {
		this.setState(
			{ displayItems: [], scrollIndex: 0 },
			this.getLazyLoadingItems
		);
	};

	getActiveItemClass = item => {
		const { selectedItem } = this.state;
		const { itemRefrenceKey } = this.props;
		if (item && selectedItem) {
			if (item === selectedItem) {
				return "selected";
			} else if (
				item.hasOwnProperty(itemRefrenceKey) &&
				selectedItem.hasOwnProperty(itemRefrenceKey)
			) {
				if (item[itemRefrenceKey] === selectedItem[itemRefrenceKey]) {
					return "selected";
				}
			}
		}
		return "";
	};

	onPageSizeChange = pageSize => {
		this.setState({
			pageSize: Number(pageSize),
			currentPage: 1,
			selectedItem: null
		});
		this.props.onPageSizeChange && this.props.onPageSizeChange(pageSize);
	};

	/** 
		This funciton is called on mouseup of resizeHandler element(element in ColumnHeader component).
		@param {number} widthDelta The amount of width that is increased after resize
		widthDelta is added to the sum of all columns width and it is applied to the scroller element.
	*/
	updateColumnWidth = widthDelta => {
		this.resizeHandlerClicked = false; //resizeHandlerClicked is false, so that on mouse leave, we can hide the resize handler element.
		if (widthDelta) {
			let colGroupItems = [...this.colGroupElement.children];
			let updatedWidth = colGroupItems
				.map(item => {
					return parseFloat(item.style.width, 10);
				})
				.reduce((sum, itemWidth) => {
					return sum + itemWidth;
				});
			updatedWidth += widthDelta;
			//isResizeStarted state is used to set cursor as 'col-resize' for the entire table. This will prevent flickericng of cursor while dragging.
			this.setState({
				dataTableContainerWidth: updatedWidth,
				isResizeStarted: false
			});
		} else {
			this.setState({ isResizeStarted: false });
		}
	};

	/* function applies <ColGroup> elements reference to this.colGroupElement */
	setColGroupRef = ref => {
		this.colGroupElement = ref;
	};

	setTHeaderRef = el => {
		this.tHeadHolderElement = el;
	};

	/* While dragging, we will show overlay element over the tBody element, so to prevent hover and unnecessary actions in the tBody element.*/
	enableTBodyOverlay = () => {
		//this.resizeHandlerClicked prevents resizie handler from hiding upon dragging the resize handler element.
		this.resizeHandlerClicked = true;
		this.setState({ isResizeStarted: true });
	};

	/* Returns the classnames which will be applied to datatable container or holder element */
	getDataTableHolderClassNames = () => {
		let dataTableHolderClassNames = [
			"p3-data-grid-table-holder " + this.props.tableHolderHeightClassName
		];
		if (this.props.allowColumnResize) {
			this.state.isResizeStarted &&
				dataTableHolderClassNames.push("p3-col-resize");
		}

		if (this.state.isSourceDataEmpty) {
			dataTableHolderClassNames.push("p3-data-grid-no-events");
		}
		return dataTableHolderClassNames.join(" ");
	};

	render() {
		const cols = this.props.columns || [];
		const dataProvider = this.state.dataProvider;
		let list;
		if (this.state.usePagination) {
			let currentPage = this.state.currentPage;
			//
			let pageSize = this.state.pageSize || dataProvider.length; // this.state.maxRowCount; // will use maxRowCount when we implement row recycling logic
			let start = (currentPage - 1) * pageSize;
			let end = start + pageSize;
			list = dataProvider ? dataProvider.slice(start, end) : null;
		} else {
			list = this.props.scrollUpdate
				? this.state.displayItems
				: dataProvider;
		}
		//const onChange = this.onChange.bind(this);
		// if column length is 0 we are setting the default width as 0
		const defaultColWidth = cols.length ? 100 / cols.length : 0;

		const rowClassName = [
			this.props.rowClassName || "p3-default-data-grid-row",
			this.props.selectable ? "p3-show-pointer" : "p3-show-arrow"
		].join(" ");

		//let resetScrollPositionOnPageChange = this.resetScrollPositionOnPageChange;
		this.resetScrollPositionOnPageChange = false;

		//console.log('inside DG render() method on %s', this.props['data-id'], new Date().getTime());
		//console.log('contanins {%d} items', list ? list.length: 0);
		let tableCSS =
			"p3-tbody-holder " + (this.props.tableContainerClassName || "");
		tableCSS += this.state.usePagination
			? " p3-grid-with-pagination-height"
			: "";
		let lastColIndex = cols.length - 1;

		const isDataTableShrinked =
			this.props.allowColumnResize &&
			parseFloat(this.state.dataTableContainerWidth) <
				this.initialDataTableWidth;
		if (
			this.state.expandedRow &&
			list.indexOf(this.state.expandedRow) >= 0
		) {
			list = [...list];
			list.splice(list.indexOf(this.state.expandedRow) + 1, 0, {
				...this.state.expandedRow,
				expandedRow: true
			});
		}
		/* ColGroup component's variable instance, used in both tables */
		let colGroupElement = (
			<ColGroup
				cols={
					!this.props.allowColumnResize ||
					this.props.persistColumnResize
						? cols
						: this.colsWithNewReference
				}
				defaultColWidth={defaultColWidth}
				allowColumnResize={this.props.allowColumnResize}
				dataTableWidth={this.state.dataTableWidth}
				setColGroupRef={this.setColGroupRef}
			/>
		);
		let tableClass = this.state.isFilteredDataHaveEntries
			? "p3-data-grid-table table"
			: "p3-data-grid-table table h-100 w-100" +
			  (this.props.className || "");
		return (
			<div
				data-auto-id={this.props["data-auto-id"]}
				className={this.getDataTableHolderClassNames()}
			>
				<div className="p3-thead-holder" ref={this.setTHeaderRef}>
					<table
						data-auto-id="p3-data-table-header"
						className={
							"p3-data-grid-table p3-data-grid-table-header table " +
							(isDataTableShrinked ? "show-right-border " : "") +
							(this.props.className || "")
						}
						style={{
							tableLayout: this.props.allowColumnResize
								? "fixed"
								: "auto",
							width: this.state.dataTableContainerWidth
						}}
					>
						{colGroupElement}
						<tbody>
							<tr>
								{cols.map((col, index) => (
									<P3ColumnHeader
										data={col}
										index={index}
										key={index}
										id={"p3-dg-header-cell-" + index}
										defaultColWidth={defaultColWidth}
										filterPopoverPlacement={
											index === lastColIndex
												? "left"
												: "bottom"
										}
										onClick={e =>
											col.allowSort &&
											this.onHeaderClick(col)
										}
										tableHeaderClassName={this.props.tableHeaderClassName}
										onApplyFilter={this.onApplyFilter}
										allowColumnResize={
											this.state.isFilteredDataHaveEntries
												? this.props.allowColumnResize
												: false
										}
										updateColumnWidth={
											this.updateColumnWidth
										} //Callback function on mouse up of resize handler element
										colsWithNewReference={
											this.colsWithNewReference
										}
										persistColumnResize={
											this.props.persistColumnResize
										}
										tBodyResizeHandlerElement={
											this.tBodyResizeHandlerElement
										}
										tBodyElement={this.tableBody}
										enableOverlay={this.enableTBodyOverlay}
										resizeHandlerClicked={
											this.resizeHandlerClicked
										}
									/>
								))}
							</tr>
						</tbody>
					</table>
				</div>
				<div className={tableCSS} ref={div => (this.tableBody = div)}>
					<ScrollArea
						className="p3-scrollbar-area"
						contentClassName={
							this.state.isFilteredDataHaveEntries
								? "p3-scrollbar-content"
								: "p3-scrollbar-content h-100 w-100"
						}
						ref={scrollArea => (this.scrollArea = scrollArea)}
						smoothScrolling={true}
						verticalContainerStyle={{
							width: "5px",
							opacity: ".4",
							zIndex: "1"
						}}
						verticalScrollbarStyle={{ width: "5px" }}
						contentStyle={{
							width: this.state.dataTableContainerWidth
						}}
						onScroll={this.onScroll}
						horizontalContainerStyle={{
							height: "6px",
							width: "1px",
							opacity: ".4",
							zIndex: "1",
							bottom: "0px"
						}}
						horizontalScrollbarStyle={{
							height: "6px",
							width: "1px"
						}}
					>
						<table
							data-auto-id="p3-data-table-body"
							className={tableClass}
							style={{
								tableLayout: this.props.allowColumnResize
									? "fixed"
									: "auto"
							}}
						>
							{colGroupElement}
							<tbody ref={div => (this.tableContainer = div)}>
								{this.state.isFilteredDataHaveEntries ? (
									list.map((row, i) => {
										if (row.expandedRow) {
											if (
												this.props.expandedRowRenderer
											) {
												return (
													<ExpandedRow
														colSpan={cols.length}
													>
														{this.props.expandedRowRenderer(
															row
														)}
													</ExpandedRow>
												);
											} else {
												return null;
											}
										} else {
											return (
												<P3DataGridRow
													key={i}
													data-auto-id={
														this.props.cmpName +
														"-" +
														i
													}
													expendable={
														this.props
															.canRowExpand &&
														this.props.canRowExpand(
															row,
															i,
															dataProvider
														)
													}
													expanded={
														this.state
															.expandedRow === row
													}
													data={row}
													allowColumnResize={
														this.props
															.allowColumnResize
													}
													columns={cols}
													className={rowClassName}
													activeClass={this.getActiveItemClass(
														row
													)}
													onExpanderClick={
														this.onExpanderClick
													}
													onRowSelect={(
														row,
														col,
														colIndex
													) =>
														this.onSelect(
															row,
															col,
															i,
															colIndex
														)
													}
												/>
											);
										}
									})
								) : this.props.showNoDataAlert ? (
									<div>No Data Found</div>
								) : null}
							</tbody>
						</table>
					</ScrollArea>
					{this.state.disabled && (
						<div className="p3-parent-blocker" />
					)}
					{this.props.allowColumnResize && (
						<div
							className="p3-tbody-resize-handler"
							ref={this.tBodyResizeHandlerElement}
						></div>
					)}
					{this.state.isResizeStarted && (
						<div className="p3-tBody-overlay"></div>
					)}
				</div>
				
				{this.state.showRecordCount && !this.state.usePagination && (
					<div className="p3-grid-pagination-holder">
						<div className="container-fluid">
							<div className="row justify-content-end">
								<div className="col-sm-auto align-self-center justify-content-end pr-3">
									<span id="p3grid_recordcount">
										{(dataProvider
											? dataProvider.length
											: 0) + " Items"}
									</span>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

/*
 */
