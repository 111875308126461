/** @format */
import PropTypes from "prop-types";
import React from "react";
import { ScrollArea } from "../p3-scrollBar";
import { P3Popup } from "../popup";
import { P3CSSSpinner } from "../progress-bar";
import arrowIcon from "./arrow.svg";
import failedIcon from "./failed-icon.png";
import infoIcon from "./info-icon.svg";
import "./status-bar.scss";
import successIcon from "./success-icon.png";
import statusIcon from "./warning.png";


export const P3Footer = props => {
	const { children, helpID = null } = props;

	let BTN_CONTAINER_CLASSES = ["p3-btn-container"];

	if (helpID) {
		BTN_CONTAINER_CLASSES.push("p3-has-float-btn");
	} else {
		BTN_CONTAINER_CLASSES.push("w-100");
	}

	BTN_CONTAINER_CLASSES = BTN_CONTAINER_CLASSES.join(" ");

	const footer = (
		<footer className="row w-100">
			<div className="w-100">
				<div className={BTN_CONTAINER_CLASSES}>{children}</div>
				{helpID && (
					<button
						type="button"
						data-auto-id={helpID}
						className="btn p3-btn p3-btn-help"
					>
						Help
					</button>
				)}
			</div>
		</footer>
	);
	return footer;
};
export class P3SuccessIcon extends React.Component {
	render() {
		return (
			<div
				className={
					"p3-status-bar-status-icon " +
					(this.props.iconClass ? this.props.iconClass : "")
				}
			>
				<img
					src={successIcon}
					alt="Success"
					className={this.props.imgClass || ""}
				/>
			</div>
		);
	}
}

export class P3FailedIcon extends React.Component {
	render() {
		return (
			<div
				className={
					"p3-status-bar-status-icon " +
					(this.props.iconClass ? this.props.iconClass : "")
				}
			>
				<img
					src={failedIcon}
					alt="Failed"
					className={this.props.imgClass ? this.props.imgClass : ""}
				/>
			</div>
		);
	}
}

export class P3WarningIcon extends React.Component {
	render() {
		const className = "p3-status-bar-status-icon " + (this.props.iconClass ? this.props.iconClass : "");
		if (this.props.isSVGIcon) {
			return <div className={className}><WarningIcon className={this.props.iconImgClass || ""} /></div>
		} else {
			return <div className={className}>
				<img className={this.props.iconImgClass || ""} src={statusIcon} alt="warning" />
			</div>
		}
	}
}

export const WarningIcon = ({ className }) => (<svg width="24" height="24" className={`p3-custom-icon ${className || ""}`} viewBox="0 0 24 24"
	fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M23.5021 18.3645L15.0234 1.82607C13.6595 -0.606201 10.3412 -0.611175 8.97728 1.82607L0.498619 18.3645C-0.893401 20.8515 0.793895 24 3.52169 24H20.4743C23.2021 24 24.8941 20.8515 23.5021 18.3645ZM12.0004 21.0156C11.227 21.0156 10.5943 20.3441 10.5943 19.5234C10.5943 18.7027 11.227 18.0312 12.0004 18.0312C12.7737 18.0312 13.4064 18.7027 13.4064 19.5234C13.4064 20.3441 12.7737 21.0156 12.0004 21.0156ZM13.4064 15.0469C13.4064 15.8676 12.7737 16.5391 12.0004 16.5391C11.227 16.5391 10.5943 15.8676 10.5943 15.0469V7.58592C10.5943 6.76521 11.227 6.09373 12.0004 6.09373C12.7737 6.09373 13.4064 6.76521 13.4064 7.58592V15.0469Z" fill="#FFC107" />
</svg>);

export class P3ArrowIcon extends React.Component {
	render() {
		return (
			<div className={this.props.iconClass ? this.props.iconClass : ""}>
				<img
					className={this.props.iconImgClass || ""}
					src={arrowIcon}
					alt="arrow"
				/>
			</div>
		);
	}
}

export class P3InfoIcon extends React.Component {
	static propTypes = {
		className: PropTypes.string
	};

	onClickHandler = () =>{
		this.props.onClick && this.props.onClick();
	}

	render() {
		return (
			<div onClick={this.onClickHandler}
				className={
					"p3-status-bar-status-icon " +
					(this.props.className ? this.props.className : "")
				}
			>
				<img src={infoIcon} height="16px" alt="Information" />
			</div>
		);
	}
}

export class P3AlertBar extends React.Component {
	getMsgClassName = (isLoading, showFullText) => {
		let classNames = [];
		if (isLoading && !showFullText) {
			classNames.push("text-truncate");
		}
		if (this.props.msgClassName) {
			classNames.push(this.props.msgClassName);
		}

		return classNames.join(" ");
	};

	getIconClassName = isLoading => {
		let classNames = ["d-flex align-items-center h-100 w-100"];

		if (isLoading) {
			classNames.push("justify-content-center");
		}
		if (this.props.className) {
			classNames.push(this.props.className);
		}

		return classNames.join(" ");
	};
	render() {
		const {
			iconClass,
			statusIcon,
			message,
			isHtmlMessage,
			showFullText
		} = this.props;
		const isSuccess = statusIcon === P3StatusBarStatus.SUCCESS;
		const isLoading =
			statusIcon === P3StatusBarStatus.LOADING ||
			statusIcon === P3StatusBarStatus.BUNDLE_LOADING;
		const isWarning = statusIcon === P3StatusBarStatus.WARNING;
		const isInfo = statusIcon === P3StatusBarStatus.INFO;
		const isFailed =
			statusIcon === P3StatusBarStatus.FAILED ||
			statusIcon === P3StatusBarStatus.BUNDLE_FAILED;
		// decide to render the icon div // added dude to empty icon making alignment issue if no icon is passed
		const showIcon =
			isSuccess || isLoading || isFailed || isWarning || isInfo;
		return (
			<div className={this.getIconClassName(isLoading)}>
				{showIcon && (
					<div
						data-auto-id={"icon_" + statusIcon}
						className="showIconClass"
					>
						{isSuccess && <P3SuccessIcon iconClass={iconClass} />}
						{isLoading && <P3CSSSpinner show={true} />}
						{isFailed && <P3FailedIcon iconClass={iconClass} />}
						{isWarning && <P3WarningIcon iconClass={iconClass} isSVGIcon />}
						{isInfo && <P3InfoIcon iconClass={iconClass} />}
					</div>
				)}
				<div className={this.getMsgClassName(isLoading, showFullText)}>
					<span
						data-auto-id={"msg_" + statusIcon}
						title={
							message && !isHtmlMessage && isLoading
								? message
								: null
						}
						className={
							"p3-word-break " + (this.props.msgClass || "")
						}
					>
						{" "}
						{!isLoading && <strong>{!statusIcon}</strong>}
						{message &&
							(isHtmlMessage ? (
								<div
									dangerouslySetInnerHTML={{
										__html: message
									}}
								/>
							) : (
								message
							))}
					</span>
				</div>
			</div>
		);
	}
}

export class P3LoadingBar extends React.Component {
	render() {
		let { isHtmlMessage } = this.props;
		let status = this.props.statusIcon
			? this.props.statusIcon
			: P3StatusBarStatus.LOADING;
		let autoIDOverlay =
			status === P3StatusBarStatus.LOADING
				? "loadingOverlay"
				: status + "_overlay";
		let autoIDContainer =
			status === P3StatusBarStatus.LOADING
				? "loadingContainer"
				: status + "_container";
		return (
			<div
				data-auto-id={autoIDOverlay}
				className="p3-status-overlay overlay"
			>
				<div
					data-auto-id={autoIDContainer}
					className="p3-loading-content-container"
				>
					<P3AlertBar
						msgClass="loadingMessage"
						statusIcon={status}
						isHtmlMessage={isHtmlMessage}
						message={
							this.props.message
								? this.props.message
								: "Loading..."
						}
					/>
				</div>
			</div>
		);
	}
}

export const P3StatusBarStatus = {
	LOADING: "LOADING",
	SUCCESS: "SUCCESS",
	FAILED: "FAILED",
	WARNING: "WARNING",
	INFO: "INFO",
	NULL: "NULL",
	QUEUED: "QUEUED",
	BUNDLE_LOADING: "bundle-loading",
	BUNDLE_FAILED: "bundle-failed"
}

export class P3AlertPopup extends React.Component {
	static propTypes = {
		width: PropTypes.string,
		height: PropTypes.string,
		statusTitle: PropTypes.string,
		statusIcon: PropTypes.oneOf(Object.values(P3StatusBarStatus)),
		message: PropTypes.string,
		closeButtonText: PropTypes.string,
		showMoreLabel: PropTypes.string,
		showLessLabel: PropTypes.string,
		showCloseIcon: PropTypes.bool,
		isHtmlMessage: PropTypes.bool,
		onAlertPopupClose: PropTypes.func,
		enableAutoUpdateBlocked: PropTypes.bool
	};
	static defaultProps = {
		width: "366px",
		height: "auto",
		statusIcon: P3StatusBarStatus.NULL,
		enableAutoUpdateBlocked: true,
		isHtmlMessage: true,
		get showMoreLabel() {
			return "Show more";
		},
		get showLessLabel() {
			return "Show less";
		},
		get closeButtonText() {
			return "OK";
		},
		showCloseIcon: true
	};
	constructor(props) {
		super(props);
		this.state = {
			isExpanded: false,
			contHeight: 0
		};
	}
	expandDetails = () => {
		this.setState({
			isExpanded: !this.state.isExpanded,
			popupPos: this.state.isExpanded ? "p3Pos" : ""
		});
	};

	render() {
		return (
			<P3Popup
				width={this.props.width}
				height={this.props.height}
				open={true}
				enableScrollBar={false}
				renderIn={this.props.renderIn}
				autoId="p3-action-popup"
				enableAutoUpdateBlocked={this.props.enableAutoUpdateBlocked}
				onClose={this.props.onAlertPopupClose}
				title={
					this.props.statusTitle
						? this.props.statusTitle
						: this.props.statusIcon
				}
				showCloseIcon={this.props.showCloseIcon}
				modelClassName="p3-alert-popup"
			>
				<div className="container-fluid pl-0 pr-0">
					<P3AlertBar
						statusIcon={this.props.statusIcon}
						message={this.props.message}
						isHtmlMessage={this.props.isHtmlMessage}
						msgClass="service_response_message"
					/>
				</div>
				{this.props.details && (
					<>
						{this.state.isExpanded && (
							<div className="p3-alert-max h-100">
								<ScrollArea>
									<div className="p3-alert-details">
										{this.props.details}
									</div>
								</ScrollArea>
							</div>
						)}
						<div
							onClick={this.expandDetails}
							className="p3-alert-expand"
						>
							<span className="p3-show-more">
								{this.state.isExpanded
									? this.props.showLessLabel
									: this.props.showMoreLabel}
							</span>
							<P3ArrowIcon
								iconImgClass={
									"arrow-up " +
									(this.state.isExpanded
										? "p3-arrow-rotate"
										: "")
								}
							/>
						</div>
					</>
				)}
				<P3Footer>
					<button
						data-auto-id="alertPopupClose"
						className="btn p3-btn"
						autoFocus={true}
						onClick={this.props.onAlertPopupClose}
					>
						{this.props.closeButtonText}
					</button>
				</P3Footer>
			</P3Popup>
		);
	}
}