/** @format */
import PropTypes from "prop-types";
import React from "react";
import { ScrollArea } from "../p3-scrollBar";
import { HTMLRenderer } from "../render-component";
import "./checkbox-radio-button.scss";
import "./form-item.scss";

//eslint-disable-next-line
const BOOTSTRAP_COL_REGEX = /\b(col)\-(\d{1,2})\b/g;

export const P3FormItem = props => {
	let {
		noLabel = false,
		children,
		errorClass = "",
		label = null,
		errorMessage = null,
		actions = [],
		actionRenderer = null,
		tooltip = null,
		rootClass = "",
		tooltipClass = "",
		containerClass = "",
		labelClassName = "col-4",
		fullHeight = false,
		actionClass = "",
		hasError = false
	} = props;

	let ROOT_CLASS_NAME = ["form-element col"];
	let CONTAINER_CLASS_NAME = [];
	let ACTION_CLASS_NAME = ["p3-item-action col-auto"];
	let ERROR_CLASS_NAME = ["p3-error-message"];
	let LABEL_CLASS_NAME = ["col-form-label"];

	// calculates the content length depends on the label length
	const getBootStrapClass = () => {
		if (!BOOTSTRAP_COL_REGEX.test(labelClassName)) {
			// default classes if no col attribute is passed when custom class is passed
			LABEL_CLASS_NAME.push("col-4");
			CONTAINER_CLASS_NAME.push("col-8");
			ERROR_CLASS_NAME.push("col-8 offset-4");
		} else {
			//code to extract the label column class length
			let labelColLength = parseInt(
				labelClassName
					.match(BOOTSTRAP_COL_REGEX)[0]
					.split("-")
					.slice(-1)
					.pop(),
				10
			); //split the class and get the number eg: col-5 => [col,5] => 5
			let containerColLength = 12 - labelColLength; // always grid max size is 12
			CONTAINER_CLASS_NAME.push(`col-${containerColLength}`);
			ERROR_CLASS_NAME.push(
				`col-${containerColLength} offset-${labelColLength}`
			); //offsetting the error message with label length
		}
	};

	if (fullHeight) {
		ROOT_CLASS_NAME.push("h-100");
		CONTAINER_CLASS_NAME.push("h-100");
	}

	if (!errorMessage && !hasError) {
		ROOT_CLASS_NAME.push("p-3 mb-20");
	} else {
		CONTAINER_CLASS_NAME.push("p3-err-border");
	}

	if (!noLabel) {
		CONTAINER_CLASS_NAME.push("row m-0 pl-0");
		getBootStrapClass();
	} else {
		ERROR_CLASS_NAME.push("col-12 p3-error-full");
		CONTAINER_CLASS_NAME.push("col-12");
	}

	CONTAINER_CLASS_NAME.push(containerClass);
	ACTION_CLASS_NAME.push(actionClass);
	ROOT_CLASS_NAME.push(rootClass);
	ERROR_CLASS_NAME.push(errorClass);
	LABEL_CLASS_NAME.push(labelClassName);

	ROOT_CLASS_NAME = ROOT_CLASS_NAME.join(" ");
	CONTAINER_CLASS_NAME = CONTAINER_CLASS_NAME.join(" ");
	ACTION_CLASS_NAME = ACTION_CLASS_NAME.join(" ");
	ERROR_CLASS_NAME = ERROR_CLASS_NAME.join(" ");
	LABEL_CLASS_NAME = LABEL_CLASS_NAME.join(" ");

	const item = (
		<div className={ROOT_CLASS_NAME}>
			{!noLabel && (
				<label className={LABEL_CLASS_NAME} htmlFor={label}>
					{label}
				</label>
			)}
			<div
				className={CONTAINER_CLASS_NAME}
				data-rh-at="top"
				data-rh={tooltip ? tooltip : null}
				data-rh-cls={tooltipClass}
			>
				{children}
				{actions && actions.length > 0 && (
					<div className={ACTION_CLASS_NAME}>
						{actions.map((action, index) =>
							actionRenderer ? (
								actionRenderer(action, index)
							) : (
								<div key={index} className="px-3 py-1">
									<span className="p3-text-not-selectable">
										{action}
									</span>
								</div>
							)
						)}
					</div>
				)}
			</div>
			{errorMessage && (
				<HTMLRenderer
					className={ERROR_CLASS_NAME}
					content={errorMessage}
				/>
			)}
		</div>
	);
	return item;
};

export const P3Form = props => {
	const {
		title = null,
		children,
		formMaxHeight = null,
		formClass = "",
		overflowVisible = false,
		getScrollbarRef
	} = props;

	let FORM_CLASS_NAME = ["row m-auto p3-form-details"];

	if (formMaxHeight) {
		FORM_CLASS_NAME.push("p3-scroll-extend-container");
	}

	FORM_CLASS_NAME.push(formClass);

	FORM_CLASS_NAME = FORM_CLASS_NAME.join(" ");

	function onSubmit(e) {
		e.preventDefault();
		return false;
	}

	const ui = (
		<form
			onSubmit={onSubmit}
			className="form-horizontal w-100 h-100"
			autoComplete={"off"}
			acceptCharset="ISO-8859-1"
		>
			{children}
		</form>
	);

	const form = (
		<div className={FORM_CLASS_NAME}>
			{title && (
				<span
					className="col-12 pl-0 pr-0"
					style={{ paddingBottom: "15px" }}
				>
					<strong>{title}</strong>
				</span>
			)}
			{formMaxHeight ? (
				<ScrollArea
					style={{
						maxHeight: formMaxHeight ? formMaxHeight + "px" : "auto"
					}}
					ref={getScrollbarRef}
					className="p3-scrollbar-area"
					contentClassName={`p3-scrollbar-content p3-scroll-extend-content${
						overflowVisible ? " p3-overflow-visible" : ""
					}`}
					smoothScrolling={true}
					verticalContainerStyle={{ width: "5px", opacity: ".4" }}
					verticalScrollbarStyle={{ width: "5px" }}
				>
					{ui}
				</ScrollArea>
			) : (
				ui
			)}
		</div>
	);
	return form;
};

export class P3RadioButton extends React.Component {
	static propTypes = {
		label: PropTypes.string.isRequired,
		isChecked: PropTypes.bool.isRequired,
		disabled: PropTypes.bool
	};

	static defaultProps = {
		id: null,
		name: null,
		label: "",
		value: "",
		isChecked: false,
		dataAutoId: "",
		col: 4,
		inputClassName: "",
		labelClassName: "",
		containerClassName: "",
		disabled: false
	};

	simulateCheckEvent = e => {
		this.props.onChange &&
			this.props.onChange({
				target: this.radioButton,
				currentTarget: this.radioButton
			});

		this.props.onClick &&
			this.props.onClick({
				target: this.radioButton,
				currentTarget: this.radioButton
			});
	};

	render() {
		let {
			isChecked,
			disabled,
			col,
			label,
			value,
			containerClassName,
			id,
			name,
			inputClassName,
			dataAutoId,
			labelClassName
		} = this.props;
		return (
			<div className={"col-" + col + " " + containerClassName}>
				<input
					id={id}
					name={name}
					value={value}
					ref={radio => (this.radioButton = radio)}
					checked={isChecked}
					className={"radio-custom d-none " + inputClassName}
					type="radio"
					disabled={disabled}
					readOnly
				/>
				<button
					type="button"
					data-checked={isChecked}
					name={name}
					value={value}
					id={id + "-button"}
					onClick={this.simulateCheckEvent}
					disabled={disabled}
					data-auto-id={dataAutoId}
					className="radio-custom-label p3-no-btn-style"
				>
					<label
						className={
							"pl-0 pointer p3-text-not-selectable " +
							labelClassName
						}
						data-checked={isChecked}
					>
						<HTMLRenderer content={label} />
					</label>
				</button>
			</div>
		);
	}
}

export class P3Checkbox extends React.Component {
	static propTypes = {
		label: PropTypes.string.isRequired,
		isChecked: PropTypes.bool.isRequired,
		col: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		disabled: PropTypes.bool
	};

	static defaultProps = {
		id: null,
		name: null,
		label: "",
		isChecked: false,
		dataAutoId: "",
		col: 4,
		tooltip: null,
		tooltipClass: "",
		inputClassName: "",
		labelClassName: "",
		containerClassName: "",
		disabled: false,
		value: ""
	};

	simulateCheckEvent = e => {
		e.stopPropagation();
		this.checkBox.click();
	};

	render() {
		let {
			tooltip,
			tooltipClass,
			isChecked,
			disabled,
			col,
			label,
			value,
			containerClassName,
			id,
			name,
			inputClassName,
			dataAutoId,
			labelClassName
		} = this.props;
		return (
			<div
				data-rh-at="top"
				data-rh={tooltip ? tooltip : null}
				data-rh-cls={tooltipClass}
				className={
					"align-self-center col-" + col + " " + containerClassName
				}
			>
				<input
					type="checkbox"
					className={
						"form-check-input checkbox-custom d-none " +
						inputClassName
					}
					name={name}
					id={id}
					ref={checkBox => (this.checkBox = checkBox)}
					value={value}
					onClick={this.props.onChange || this.props.onClick}
					checked={isChecked}
					disabled={disabled}
					readOnly
				/>
				<button
					type="button"
					name={name}
					onClick={this.simulateCheckEvent}
					disabled={disabled}
					data-auto-id={dataAutoId}
					value={value}
					className="checkbox-custom-label p3-no-btn-style"
					data-checked={isChecked}
				>
					<label
						className={
							"px-0 txt-middle pointer p3-text-not-selectable " +
							labelClassName
						}
						data-checked={isChecked}
					>
						{label}
					</label>
				</button>
			</div>
		);
	}
}

export class P3SliderSwitch extends React.Component {
	static propTypes = {
		isChecked: PropTypes.bool.isRequired,
		disabled: PropTypes.bool
	};

	static defaultProps = {
		id: null,
		name: null,
		isChecked: false,
		dataAutoId: "p3-slider-switch",
		tooltip: null,
		tooltipClass: "",
		inputClassName: "",
		containerClassName: "",
		disabled: false,
		value: ""
	};

	simulateCheckEvent = e => {
		e.stopPropagation();
		this.checkBox.click();
	};

	render() {
		let {
			tooltip,
			tooltipClass,
			isChecked,
			disabled,
			value,
			containerClassName,
			id,
			name,
			inputClassName,
			dataAutoId
		} = this.props;
		return (
			<div
				data-rh-at="top"
				data-rh={tooltip ? tooltip : null}
				data-rh-cls={tooltipClass}
				className={"align-self-center " + containerClassName}
			>
				<input
					type="checkbox"
					className={
						"form-check-input checkbox-switch-custom d-none " +
						inputClassName
					}
					name={name}
					id={id}
					ref={checkBox => (this.checkBox = checkBox)}
					value={value}
					onClick={this.props.onChange || this.props.onClick}
					checked={isChecked}
					disabled={disabled}
					readOnly
				/>
				<button
					type="button"
					name={name}
					onClick={this.simulateCheckEvent}
					disabled={disabled}
					data-auto-id={dataAutoId}
					value={value}
					className="p3-checkbox-switch p3-no-btn-style"
					data-checked={isChecked}
				>
					<span class="p3-checkbox-slider round"></span>
				</button>
			</div>
		);
	}
}
