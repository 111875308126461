/**@format */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./main-view.scss";
import CameraSizer from "./pages/camera-sizer";
import { ChassisConfiguration } from "./pages/chassis-config";
import { P3AlertPopup, P3LoadingBar, P3StatusBarStatus } from "./../components/status-bar/status-bar"
import Summary from "./pages/summary";
import { useKeycloak } from "@react-keycloak/web";
// import { createHttpLink, useMutation } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { LOAD_USERS } from "../graphql/queries";

const defaultSizer = {
	number_of_cameras: 1, frames_per_second: 30, retention_days: 20, resolution: "",
	compression: "", scene_activity: "", compression_ratio: "", recording_profile: ""
};

/**
 * Sizer Tool
 */

export function MainView() {
	const { keycloak } = useKeycloak();
	const { error, loading, data } = useQuery(LOAD_USERS, {
		context: {
			headers: {
				"authorization": "Bearer " + keycloak.token,
				"Content-Type": "application/json"
			}
		},
		variables: {
			CameraInputData: null
		}
	});
	const [inputData, setCollection] = useState([]);
	const [currentStep, setCurrentStep] = useState(0);
	const [currentError, setCurrentError] = useState(error);
	// const { nvrData } = useQuery(LOAD_NVR,{
	// 	variables: {
	// 		total_bandwidth_mbps : band, total_capacity : disk
	// }});
	//const [setChassis, response] = useMutation(SET_CHASSIS_MUTATION);
	const changeStep = (step) => {
		setCurrentStep(currentStep + step);
	}

	useEffect(() => {
		if (data) {
			setCollection(data);
		}
	}, [data]);
	useEffect(() => {
		if (keycloak.token && error) {
			setCurrentError(error);
		}
	}, [error]);
	if (loading) return <P3LoadingBar
							statusIcon={P3StatusBarStatus.LOADING}
							message="Loading"
						/>;
	if (currentError) {
		return <P3AlertPopup
			statusIcon={P3StatusBarStatus.FAILED}
			message={currentError}
			onAlertPopupClose={() => setCurrentError(false)}
		/>;
	}


	// let query = "query IntrospectionQuery{query{\n  camera_resolutions{\n    description\n    name\n  }\n  compression_ratios{\n    description\n    name\n    value\n  }\n  compressions{\n    compressions\n    description\n  }\n  recording_profiles{\n    description\n    name\n    value\n  }\n  scene_activities{\n    description\n    name\n    value\n  }\n  video_recoder_chassis{\n    description\n    name\n  }\n  video_recorder_models{\n    description\n    name\n  }\n  video_recorder_sizes{\n    description\n    name\n  }\n  \n}\n}";
	// let subgraphEndPoint = 'http://localhost:9090/v1/graphql';
	// const link = from([
	// 	errorLink,
	// 	new HttpLink({ uri: "http://localhost:9090/v1/graphql", fetchOptions: {
	// 	  mode: 'no-cors',
	// 	} }),
	//   ]);
	// //let link = createHttpLink({ uri: subgraphEndPoint });
	// let cache = new InMemoryCache({addTypename: false});
	// let variables = {}
	// let apolloClient = new ApolloClient({ link, cache });
	// let gqlQuery = gql(query);
	// apolloClient.query({query: gqlQuery,variables,fetchPolicy: 'no-cache'}).then(console.log);
	if (keycloak.token) {
		return (
			<MainPage data={inputData} currentStep={currentStep} changeStep={changeStep} keycloakToken={keycloak.token} />
		)
	} else {
		return (
			<P3LoadingBar
				statusIcon={P3StatusBarStatus.LOADING}
				message="Loading"
			/>
		)
	}
}

export class MainPage extends React.Component {
	static propTypes = {
		onClose: PropTypes.func,
		onStatusAlert: PropTypes.func
	};
	constructor(props) {
		super(props);
		let cameras = [JSON.parse(JSON.stringify(defaultSizer))];
		this.state = {
			isProgress: false,
			isAlertPopup: false,
			currentStep: props.currentStep,
			diskSpace: 0,
			bandWidth: 0,
			camDatas: [],
			isProjectSubmit: false,
			selectedNvr: 0,
			nvrIndex: 0,
			smartNvrIndex: 0,
			selectedNetwork: "",
			nvr: [],
			statusIcon:P3StatusBarStatus.FAILED,
			projectName: "Project_",// + Date.now(),
			defaultActivePanelIndices: [0],
			networkTypes: [],
			summaryItems: [],
			cameras,
			isValid: this.isValidInputs(cameras),
			chassisItems: []
		};
	}

	onPrevHandler = () => {
		this.props.changeStep(-1);
		//this.updateSummary();
	}
	onNextHandler = () => {
		if (this.state.isProjectSubmit) {
			this.props.changeStep(-2);
			this.setState({
				isProjectSubmit: false,
				cameras: [JSON.parse(JSON.stringify(defaultSizer))],
				camDatas: [],
				projectName: "Project_",
				selectedNvr: 0,
				nvrIndex: 0,
				smartNvrIndex: 0,
			});
		} else {
			this.props.changeStep(1);
		}
	}

	updateValue = (obj) => {
		this.setState({ ...obj });
	}

	addPage = () => {
		let cameras = this.state.cameras;
		let defaultActivePanelIndices = this.state.defaultActivePanelIndices;
		defaultActivePanelIndices.push(cameras.length);
		cameras.push(JSON.parse(JSON.stringify(defaultSizer)));
		this.setState({ cameras, defaultActivePanelIndices });
	}

	modifyPage = (item, value, index, data = null) => {
		if (item === "disk") {
			let camDatas = this.state.camDatas;
			camDatas[index] = value;
			this.setState({ camDatas });
			return;
		}
		let cameras = this.state.cameras;
		cameras[index][item] = value;
		let isValid = this.isValidInputs(cameras);

		this.setState({ cameras, isValid });
	}

	isValidInputs = (cameras) => {
		let isValid = cameras.some((item) => {
			return !Number(item.retention_days) > 0 || !Number(item.number_of_cameras) > 0 || !Number(item.frames_per_second) > 0 || item.compression === "" ||
				item.compression_ratio === "" || item.recording_profile === "" || item.resolution === "" || item.scene_activity === ""
		})
		return !isValid;
	}

	getCalculatedValue = (args) => {
		//const {cameras,bandWidth} = args
		return { bandWidth: 300 + Math.ceil(Math.random() * 400), diskSpace: 600 + Math.ceil(Math.random() * 400) };
	}

	onDelete = (index) => {
		let cameras = this.state.cameras;
		cameras.splice(index, 1);
		let isValid = this.isValidInputs(cameras);
		let camDatas = this.state.camDatas;
		camDatas.splice(index, 1);
		this.setState({ cameras, isValid, camDatas });
	}

	onChange = (param, value) => {
		this.setState({ [param]: value });
	}

	changeActiveIndex = (defaultActivePanelIndices) => {
		this.setState({ defaultActivePanelIndices });
	}

	getnumber_of_cameras = () => {
		return this.state.isValid && this.state.cameras.reduce((a, b) => a + ((b["number_of_cameras"]) || 0), 0) > 0
	}

	componentDidUpdate(prevProps, _prevState) {
		if (this.props.data !== prevProps.data) {
			this.setState({
				chassisItems: this.props.data.getChassisItems
			});
		}
		if (this.props.currentStep !== prevProps.currentStep) {
			this.setState({
				currentStep: this.props.currentStep
			});
		}
	}

	sum = (arr, param) => {
		return arr.reduce((a, b) => a + ((b[param]) || 0), 0)
	}

	getNvr = (nvr, setDefault = true) => {
		setDefault && nvr.map((item, index) => {
			item.children.map((child, ind) => {
				child.isActive = (ind === ((index === 0)?this.state.smartNvrIndex:this.state.nvrIndex));
				return child;
			})
			item.isActive = (index === 0);
			return item;
		});
		this.setState({ nvr });
	}

	submitProject = (projectName, callBack) => {
		let bandWidth = Math.round(this.sum(this.state.camDatas, "bandwidth"));
		let diskSpace = Math.round(this.sum(this.state.camDatas, "disk_space"));
		let nvr = this.state.nvr[this.state.selectedNvr].children[(this.state.selectedNvr === 0 ? this.state.smartNvrIndex : this.state.nvrIndex)]

		let nvrQuote = {
			"selected_recorder_id": nvr.uuid,
			"selected_nic": this.state.networkTypes ? this.state.selectedNetwork : "",
			"num_nodes": nvr.num_nodes,
			"recommendation_type": nvr.recommendation_type,
			"project_name": projectName
		}
		let chosen_input = JSON.stringify({ cameras: this.state.cameras, bandWidth, diskSpace, nvrQuote });
		nvrQuote.chosen_input = chosen_input;
		this.setState({ isProjectSubmit: true }, ()=>callBack(nvrQuote));
	}

	summaryError = (errorMessage) =>{
		this.setState({isAlertPopup:true, errorMessage, isProjectSubmit: false});
	}

	openAlert = (errorMessage,statusIcon) =>{
		this.setState({isAlertPopup:true, errorMessage, statusIcon});
	}

	render() {
		const bandWidth = this.sum(this.state.camDatas, "bandwidth");
		const diskSpace = this.sum(this.state.camDatas, "disk_space");
		let enableNext = this.getnumber_of_cameras() && bandWidth > 0 && diskSpace > 0;
		let stepsTitle = [
			{ label: "Calculate Bandwidth and Storage" },
			{ label: "NVR Configuration" },
			{ label: "Summary" }]
		return (
			<div className="container w-100 h-100">
				<ul className="horizontalStep">
					{stepsTitle.map((step, index) => {
						return (
							<StepInfo
								step={step}
								key={index}
								index={index + 1}
								currentStep={this.state.currentStep}
								maxSteps={stepsTitle.length}
							/>
						);
					})}
				</ul>
				<div className="content-page">
					{this.state.currentStep === 0 && <CameraSizer
						isValid={this.state.isValid}
						data={this.props.data}
						keycloak={this.props.keycloak}
						bandWidth={bandWidth}
						diskSpace={diskSpace}
						changeActiveIndex={this.changeActiveIndex}
						defaultActivePanelIndices={this.state.defaultActivePanelIndices}
						onDelete={this.onDelete}
						modifyPage={this.modifyPage}
						addPage={this.addPage}
						openAlert={this.openAlert}
						cameras={this.state.cameras} />}
					{this.state.currentStep === 1 && <ChassisConfiguration
						networkTypes={this.state.networkTypes}
						modifyPage={this.modifyPage}
						updateValue={this.updateValue}
						getNvr={this.getNvr}
						keycloakToken={this.props.keycloakToken}
						nvrIndex={this.state.nvrIndex}
						smartNvrIndex={this.state.smartNvrIndex}
						bandWidth={bandWidth}
						diskSpace={diskSpace}
						openAlert={this.openAlert}
						selectedNetwork={this.state.selectedNetwork}
						nvr={this.state.nvr}
						selectedNvr={this.state.selectedNvr}
						onChange={this.onChange} />}
					{this.state.currentStep === 2 && <Summary
						projectName={this.state.projectName}
						updateValue={this.updateValue}
						keycloakToken={this.props.keycloakToken}
						submitProject={this.submitProject}
						openAlert={this.openAlert}
						isProjectSubmit={this.state.isProjectSubmit}
						summaryError={this.summaryError}
						summaryItems={this.state.summaryItems} />}
				</div>
				<div className="p3-button-container">
					{this.state.currentStep > 0 && <button
						id="prevButton"
						data-auto-id="prevButton"
						className={"btn  p3-btn " + (this.state.isProjectSubmit ? "hideNext" : "")}
						onClick={this.onPrevHandler}
						disabled={
							this.state.currentStep === 0
						}
					>Back</button>}
					{!this.state.isProjectSubmit ? <button
						id="nextButton"
						disabled={!enableNext}
						data-auto-id="nextButton"
						className={"btn p3-btn " + (this.state.currentStep > 1 ? "hideNext" : "")}
						onClick={this.onNextHandler}
					>Next</button> :
						<button
							id="nextButton"
							data-auto-id="nextButton"
							className={"btn p3-btn "}
							onClick={this.onNextHandler}
						>Start Over Again</button>}
				</div>
				{this.state.isAlertPopup && <P3AlertPopup
					statusIcon={this.state.statusIcon}
					message={this.state.errorMessage}
					onAlertPopupClose={() => this.setState({isAlertPopup:false})}
				/>}
			</div>
		);
	}
}

export default MainView;
export const StepInfo = props => {

	const {
		index,
		step,
		currentStep,
		maxSteps
	} = props;

	// if (index <= maxSteps) {
	// 	showStepTitle = true;
	// 	if (disabledSteps.indexOf(index) !== -1 && currentStep > index) {
	// 		classNames.push("in-active");
	// 	} else {
	// 		if (index < currentStep) {
	// 			// visited views
	// 			classNames.push("visited");
	// 		} else if (index > currentStep) {
	// 			// next views
	// 			classNames.push("not-visited");
	// 		} else {
	// 			// current view
	// 			if (step.status) {
	// 				classNames.push("visited");
	// 			} else {
	// 				classNames.push("active");
	// 			}
	// 		}
	// 	}
	// }
	let activeClass = currentStep === index - 1 ? "active-circle" : "inactive-circle"
	return (
		<div className="step-parent">
			<div className="step-cont col">
				<div className={"step-circle " + activeClass}>
					{index}
				</div>
				<span className="step-desc">{step.label}</span>
			</div>
			{index < maxSteps && (
				<HorizontalStepInfoArrow />
			)}
		</div>
	);
};
export const HorizontalStepInfoArrow = ({ className }) => (
	<svg width="110" height="12" viewBox="0 0 110 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M110 6L100 0.226497V11.7735L110 6ZM0 7H101V5H0V7Z" fill="#97A0AF" />
	</svg>
);

export const SelectDropdown = props => {
	const { dataProvider, labelField, valueField, selectedValue, className, onChange, name, disabled=false } = props;
	if (!dataProvider) {
		return <></>;
	}
	if (selectedValue === "" && dataProvider.length) {
		onChange(dataProvider[0][valueField], name);
	}
	function onSelectChange(e) {
		onChange(e.target.value, name);
	}
	return (
		<select className={className} value={selectedValue} onChange={onSelectChange} disabled={disabled}>
			<option key={name} style={{ display: "none" }}>Select</option>
			{dataProvider.map((item, index) => { return (<option key={name + index} item={item} index={index} value={item[valueField]}>{item[labelField]}</option>) })}
		</select>
	)
}