/** @format */
import PropTypes from "prop-types";
import React from "react";

/**
 * P3TextBox is custom text box component
 * Validations enabled if set error message to the corresponding error message field [required, minLength, maxLength, specialCharater] in "errorMessages" property
 * ex:if enable required field validation, need to set error message like
 * errorMessages: {
        required: "Enter the Name" 
    }
 * 
 validate and notify Event Type as 'default' instead of 'blur' in following case
    1. validate value at componentDidMount event
    2. validate value at componentWillReceiveProps - Switch from disabled into enabled (prop[disabled]) 
*/
export class P3TextBox extends React.Component {
	static propTypes = {
		fieldName: PropTypes.string.isRequired,
		"data-auto-id": PropTypes.string,
		className: PropTypes.string,
		checkSpecialCharValidation: PropTypes.func, //if valid return true or empty string else return false or error message
		onNotify: PropTypes.func,
		onKeyPress: PropTypes.func,
		maxLength: PropTypes.number,
		minLength: PropTypes.number,
		hasShowErrorOnChange: PropTypes.bool,
		preventOnChange: PropTypes.func, // if return true onChange fired else not.
		//errorMessages: PropTypes.Object,
		// errorMessages: {
		//     required: PropTypes.string,
		//     minLength: PropTypes.string,
		//     maxLength: PropTypes.string,
		//     specialCharater:PropTypes.string
		// }
		regEx: PropTypes.string,
		disabled: PropTypes.bool,
		placeholder: PropTypes.string
	};

	//Initialize default Props
	static defaultProps = {
		value: "",
		autoFocus: false,
		className: "col-12",
		type: "text",
		min:"0",
		oninput:"validity.valid||(value='')",
		maxLength: null,
		minLength: null,
		tooltip: null,
		tooltipClass: "",
		errorMessages: {},
		inValidCharacterErrorMessage: "",
		regEx: "[0-9a-zA-Z-]+",
		checkSpecialCharValidation: null,
		hasShowErrorOnChange: false,
		preventOnChange: null,
		placeholder: "",
		renderAsTextArea: false,
		rows: 2
	};

	/** Constructor */
	constructor(props) {
		super(props);

		let {
			fieldName,
			value,
			className,
			maxLength,
			minLength,
			disabled,
			errorMessages,
			regEx,
			hasShowErrorOnChange
		} = this.props;

		this.state = {
			fieldName,
			value,
			className,
			maxLength,
			minLength,
			disabled,
			errorMessages,
			regEx: this.convertStringToRegEx(regEx),
			hasShowErrorOnChange
		};
	}

	//Component Did Mount
	componentDidMount() {
		if (this.state.value && this.state.value.length) {
			this._onBlur(this.state.value, "default");
		} else {
			this.inputChange(this.state.value, this.state.disabled);
		}
	}

	/**
	 * Component Will Receive Props
	 * @param {object} nextProps
	 */
	componentWillReceiveProps(nextProps) {
		//state properties
		const fields = [
			"fieldName",
			"value",
			"className",
			"maxLength",
			"minLength",
			"disabled",
			"hasShowErrorOnChange"
		];
		let hasValueChanged = false;
		let newState = {};

		fields.forEach(field => {
			if (this.state[field] !== nextProps[field]) {
				hasValueChanged = true;
				newState[field] = nextProps[field];
			}
		});

		if (this.props.regEx !== nextProps.regEx) {
			hasValueChanged = true;
			newState.regEx = this.convertStringToRegEx(nextProps.regEx);
		}

		if (!this.state.errorMessages.isEquals(this.props.errorMessages)) {
			hasValueChanged = true;
			newState["errorMessages"] = nextProps["errorMessages"];
		}

		if (
			nextProps.disabled !== this.state.disabled &&
			nextProps.disabled === false
		) {
			this.setState(newState, () => {
				if (this.state.value && this.state.value.length) {
					this._onBlur(this.state.value, "default");
				} else {
					this.inputChange(
						this.state.value,
						this.state.disabled,
						"default"
					);
				}
			});
		} else if (hasValueChanged) {
			this.setState(newState, () =>
				this.inputChange(
					this.state.value,
					this.state.disabled,
					"default"
				)
			);
		}
	}

	convertStringToRegEx(regEx) {
		return new RegExp(regEx);
	}

	/**
	 * On Change envent invoked an input value changed
	 * * @param {object} e
	 */
	onChange = e => {
		if (
			!this.props.preventOnChange ||
			this.props.preventOnChange(e.target.value)
		) {
			this.inputChange(e.target.value, this.state.disabled, e.type, e);
		}
	};

	/**
	 * On inputChange function invoked an input value changed
	 */
	inputChange = (value, hasInputDisabled = false, eventType = null, e) => {
		let updateState = this.isInputValid(value, hasInputDisabled);
		this.setState(updateState);
		/**
		 * removing error message on switching from disable to enable
		 */
		(eventType === "default" || !this.state.hasShowErrorOnChange) &&
			(updateState.errorMessage = "");

		this.onNotify({ ...updateState }, eventType, e);
	};

	/**
	 * On Focus envent invoked an input getting focus
	 */
	onFocus = e => {
		let { value, isValid } = this.state;
		this.onNotify({ value, errorMessage: "", isValid }, e.type, e);
	};

	/* onClick and onKeyDown event hides the tooltip element */
	hideTooltip = () => {
	};

	/**
	 * On onBlur invoked an input tab changed or focus out
	 */
	onBlur = e => this._onBlur(e.target.value, e.type, e);

	_onBlur = (value, evtType, e) => {
		let updateState = this.isInputValid(value, this.state.disabled);
		this.setState(updateState);
		this.onNotify(updateState, evtType, e);
	};

	/**On Notify function used to intimate if the input value changed or valid  */
	onNotify = (data, eventType, e) => {
		let fieldName = this.state.fieldName;
		let response = {
			[fieldName]: data.value,
			[fieldName + "IsValid"]: data.isValid,
			[fieldName + "ErrorMessage"]: data.errorMessage
		};
		this.props.onNotify && this.props.onNotify(response, eventType, e);
	};

	/** Is Input Valid function is used to valid input value */
	isInputValid = (value, hasInputDisabled = false) => {
		let isValid = true;
		let errorMessage = "";
		let requiredErrMsg = this.state.errorMessages.required;
		let minLengthErrMsg = this.state.errorMessages.minLength;
		let maxLengthErrMsg = this.state.errorMessages.maxLength;
		let specialCharaterErrMsg = this.state.errorMessages.specialCharater;
		let isSpecialCharCustomFuncAvailable =
			typeof this.props.checkSpecialCharValidation === "function";

		if (!hasInputDisabled) {
			if (
				requiredErrMsg &&
				(!value || value.toString().trimStart().length === 0)
			) {
				errorMessage = requiredErrMsg;
				isValid = false;
			} else if (value && value.length) {
				if (specialCharaterErrMsg || isSpecialCharCustomFuncAvailable) {
					if (isSpecialCharCustomFuncAvailable) {
						//custom func
						let checkValid = this.props.checkSpecialCharValidation(
							value
						); // return either TRUE,FALSE or error message

						if (typeof checkValid === "boolean" && !checkValid) {
							errorMessage = specialCharaterErrMsg;
							isValid = false;
						} else if (
							typeof checkValid === "string" &&
							checkValid
						) {
							errorMessage = checkValid; // checkValid contain dynamic error message
							isValid = false;
						}
					} else {
						if (this.state.regEx && !this.state.regEx.test(value)) {
							errorMessage = specialCharaterErrMsg;
							isValid = false;
						}
					}
				}

				if (
					minLengthErrMsg &&
					this.state.minLength &&
					value.length < this.state.minLength
				) {
					errorMessage = minLengthErrMsg;
					isValid = false;
				} else if (
					maxLengthErrMsg &&
					this.state.maxLength &&
					value.length > this.state.maxLength
				) {
					errorMessage = maxLengthErrMsg;
					isValid = false;
				}
			}
		}
		return { errorMessage, isValid, value };
	};

	onMouseUp = (e) =>{
		e.stopPropagation();
	}

	/**render the input tag */
	render() {
		const {
			tooltip,
			tooltipClass,
			renderAsTextArea,
			fieldName,
			className,
			checkSpecialCharValidation,
			onNotify,
			maxLength,
			minLength,
			hasShowErrorOnChange,
			preventOnChange,
			errorMessages,
			regEx,
			inValidCharacterErrorMessage,
			rows,
			...inputProps
		} = this.props;
		const controlProps = {
			//type: "text",
			id: this.state.fieldName,
			// ["data-rh"]: tooltip,
			// ["data-rh-at"]: "top",
			// ["data-rh-cls"]: tooltip ? tooltipClass : "d-none",
			name: this.state.fieldName,
			value: this.state.value,
			onFocus: this.onFocus,
			onBlur: this.onBlur,
			onChange: this.onChange,
			onClick: this.hideTooltip,
			onKeyDown: this.hideTooltip,
			className: this.state.className,
			disabled: this.state.disabled
		};
		if (!renderAsTextArea) {
			return <input onMouseUp={this.onMouseUp} {...inputProps} {...controlProps} />;
		} else {
			return (
				<textarea {...controlProps} rows={this.props.rows}></textarea>
			);
		}
	}
}
