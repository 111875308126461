/** @format */
import noScroll from "no-scroll";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Portal from "react-minimalist-portal";
// import { EventPool } from "../../managers/event-pool/event-pool";
// import { KeyBoardCode, POPUP } from "../../model/enums";
//import { ModelVo } from "../../model/model-vo";
//import { TaskUtil } from "../../utils/task-util";
// import { UserModel } from "../../utils/user-model-util";
import { P3Footer } from "../status-bar/status-bar";
import { P3LoadingBar } from "../status-bar/status-bar";
import ScrollArea from "./../p3-scrollBar/ScrollAreaWithCss";
import "./p3popup.scss";

export const CloseIcon = ({ className, ...props }) => (
	<svg
		className={"p3-custom-icon " + className || ""}
		{...props}
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M6.97969 5.96697L11.8397 1.10697C11.9489 0.979437 12.006 0.815386 11.9995 0.647602C11.993 0.479818 11.9235 0.320659 11.8047 0.201929C11.686 0.0831994 11.5268 0.0136441 11.3591 0.00716337C11.1913 0.000682604 11.0272 0.0577536 10.8997 0.166971L6.03969 5.02697L1.1797 0.160304C1.05216 0.0510868 0.888111 -0.00598358 0.720327 0.00049719C0.552543 0.00697796 0.393384 0.0765326 0.274654 0.195262C0.155924 0.313992 0.086369 0.473151 0.0798883 0.640935C0.0734075 0.808719 0.130479 0.97277 0.239696 1.1003L5.0997 5.96697L0.233029 10.827C0.163241 10.8867 0.106561 10.9603 0.0665457 11.043C0.0265302 11.1257 0.00404311 11.2158 0.000496786 11.3076C-0.00304954 11.3994 0.0124211 11.491 0.0459375 11.5765C0.0794538 11.6621 0.130293 11.7398 0.195262 11.8047C0.260232 11.8697 0.33793 11.9205 0.423481 11.9541C0.509031 11.9876 0.600585 12.003 0.692398 11.9995C0.784211 11.996 0.874299 11.9735 0.957009 11.9335C1.03972 11.8934 1.11326 11.8368 1.17303 11.767L6.03969 6.90697L10.8997 11.767C11.0272 11.8762 11.1913 11.9333 11.3591 11.9268C11.5268 11.9203 11.686 11.8507 11.8047 11.732C11.9235 11.6133 11.993 11.4541 11.9995 11.2863C12.006 11.1186 11.9489 10.9545 11.8397 10.827L6.97969 5.96697Z" />
	</svg>
);

var actionName = [
	"apply",
	"yes",
	"submit",
	"delete",
	"rename",
	"shutdown",
	"reboot",
	"remove",
	"add",
	"modify",
	"edit",
	"update",
	"retrieve license",
	"activate license",
	"create",
	"take snapshot",
	"take snapshot & replicate",
	"clone snapshot",
	"toggle",
	"run",
	"clean",
	"cancel selected task",
	"confirm",
	"enable snmp",
	"start upgrade",
	"enable",
	"reset",
	"test",
	"phone home now",
	"disable"
]; // Should be in lower case

export class P3Popup extends Component {
	static propTypes = {
		closeOnEsc: PropTypes.bool,
		closeOnOverlayClick: PropTypes.bool,
		onClose: PropTypes.func,
		open: PropTypes.bool.isRequired,
		children: PropTypes.node,
		title: PropTypes.string,
		showCloseIcon: PropTypes.bool,
		closeIconSize: PropTypes.number,
		width: PropTypes.string,
		height: PropTypes.string,
		isModal: PropTypes.bool,
		scrollBarFullHeight: PropTypes.bool,
		enableAutoUpdateBlocked: PropTypes.bool,
		enableScrollBar: PropTypes.bool,
		isDraggable: PropTypes.bool,
		titleStatusIconClass: PropTypes.string,
		/* 
	  This property should be enabled only for popup whose height is small.  This is used to display all values of select component.
	  This will set overflow:visible for the popup, so it wont affect the select menu
	*/
		isOverFlowVisible: PropTypes.bool,
		onScrollRef: PropTypes.func
	};

	static defaultProps = {
		closeOnEsc: false,
		closeOnOverlayClick: true,
		showCloseIcon: true,
		closeIconSize: 20,
		contentClassName: "",
		showMaximizeIcon: false,
		children: null,
		width: "auto",
		height: "auto",
		isMaximize: false,
		isModal: true, // if it is set to 'false' ,the user can access the elements in the background,
		scrollBarFullHeight: false, //ScrollBar ocuupy full hight
		enableAutoUpdateBlocked: true,
		enableScrollBar: true,
		isDraggable: true,
		titleStatusIconClass: "",
		isOverFlowVisible: false
	};
	constructor(props) {
		super(props);
		this.handleKeydown = this.handleKeydown.bind(this);
		this.onClickOverlay = this.onClickOverlay.bind(this);
		this.onClickCloseIcon = this.onClickCloseIcon.bind(this);
		this.state = {
			showPortal: props.open,
			open: props.open,
			showCloseIcon: props.showCloseIcon
		};
		//ModelVo.autoUpdateBlocked = props.enableAutoUpdateBlocked;
	}

	componentDidMount() {
		if (this.props.closeOnEsc) {
			document.addEventListener("keydown", this.handleKeydown);
		}
		if (this.props.isDraggable) {
			document.addEventListener("onmouseleave", this.stopMoving);
		}
	}

	move = (divid, xpos, ypos) => {
		divid.style.left = xpos + "px";
		divid.style.top = ypos + "px";
	};

	startMoving = evt => {
		if (this.props.isDraggable && !this.state.isMaximize) {
			evt.stopPropagation();
			evt.preventDefault();

			let modelPopup = this.modal;
			let container = this.overlay;
			evt = evt || window.event;
			var posX = evt.clientX,
				posY = evt.clientY,
				divTop = modelPopup.style.top,
				divLeft = modelPopup.style.left,
				eWi = parseInt(modelPopup.style.width),
				eHe = parseInt(modelPopup.style.height),
				cWi = parseInt(container.style.width),
				cHe = parseInt(container.style.height);
			container.style.cursor = "move";
			container.style.overflow = "hidden";
			divTop = divTop.replace("px", "");
			divLeft = divLeft.replace("px", "");
			var diffX = posX - divLeft,
				diffY = posY - divTop;

			document.onmousemove = evt => {
				evt.stopPropagation();
				evt.preventDefault();
				evt = evt || window.event;
				var posX = evt.clientX,
					posY = evt.clientY,
					aX = posX - diffX,
					aY = posY - diffY;
				if (aX + eWi > cWi) aX = cWi - eWi;
				if (aY + eHe > cHe) aY = cHe - eHe;
				this.move(modelPopup, aX, aY);
			};
		}
	};

	stopMoving = container => {
		if (this.props.isDraggable) {
			this.overlay && (this.overlay.style.cursor = "default");
			document.onmousemove = function() {};
		}
	};

	componentWillReceiveProps(nextProps) {
		// this.props.enableAutoUpdateBlocked &&
		// 	(ModelVo.autoUpdateBlocked = nextProps.open);
		if (!this.props.open && nextProps.open) {
			this.setState(
				{
					open: true,
					showPortal: true
				},
				() => {
					this.blockScroll();
				}
			);
		}
		if (this.props.open && !nextProps.open) {
			this.setState({
				open: false
			});
			// Let the animation finish
			this.timeout = setTimeout(() => {
				this.setState({
					showPortal: false
				});
				try {
					const us = () => {
						this.unblockScroll();
					};
					us();
				} catch (error) {}
			}, 500);
		}

		if (this.state.scrollBarFullHeight !== nextProps.scrollBarFullHeight) {
			this.setState({
				scrollBarFullHeight: nextProps.scrollBarFullHeight
			});
		}

		if (typeof this.props.onScrollRef !== typeof nextProps.onScrollRef) {
			this.setState({ onScrollRef: nextProps.onScrollRef });
		}

		// TODO :: handle closeOnEscape
	}

	componentWillUnmount() {
		// this.props.enableAutoUpdateBlocked &&
		// 	(ModelVo.autoUpdateBlocked = false);

		// TaskUtil.execuiteBlockedDomainRefresh();
		if (this.props.closeOnEsc) {
			document.removeEventListener("keydown", this.handleKeydown);
		}

		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	closeMe() {
		// this.props.enableAutoUpdateBlocked &&
		// 	(ModelVo.autoUpdateBlocked = false);

		if (this.props.onClose) this.props.onClose();
		else {
			this.setState({
				open: false,
				showPortal: false
			});
		}
	}

	minimize() {
		this.props.onMinimizeRequest && this.props.onMinimizeRequest();
	}

	maximize = () => {
		this.setState({ isMaximize: !this.state.isMaximize }, this.updatePopup);
	};

	updatePopup = () => {
		// EventPool.fire(
		// 	POPUP.SIZE_CHANGED,
		// 	this.state.isMaximize,
		// 	this.props.resizeInitiator
		// );
	};

	onClickOverlay(e) {
		//  this.props.onClose();
	}

	onClickCloseIcon(e) {
		e.stopPropagation();
		this.closeMe();
	}

	onClickMinimizeIcon = e => {
		e.stopPropagation();
		this.minimize();
	};

	onClickMaximizeIcon = e => {
		e.stopPropagation();
		this.maximize();
	};

	handleKeydown(e) {
		// if (e.keyCode === KeyBoardCode.ESC_KEY) {
			// escape
			// this.closeMe();
		// }
	}

	// eslint-disable-next-line class-methods-use-this
	blockScroll() {
		noScroll.on();
	}
	unblockScroll() {
		noScroll.off();
	}

	//Parses the child element
	getChildElement = (self, childElements) => {
		if (Array.isArray(childElements)) {
			//if the child element is an array
			var childArray = [];
			childElements.forEach(function(element, i) {
				//iterate for each element
				childArray[i] = self.modifyChildElements(element);
			}, this);
			childElements = childArray;
		} else {
			childElements = self.modifyChildElements(childElements);
		}
		return childElements;
	};

	//Read only view changes for the footer button
	modifyChildElements = childElement => {
		var self = this;
		var childElements = React.Children.map(
			childElement,
			(buttonElement, i) => {
				if (buttonElement) {
					var childWithProps = buttonElement;
					if (buttonElement.props) {
						if (buttonElement.type === "button") {
							//passes only button elements
							var childName = buttonElement.props.children;
							if (Array.isArray(childName)) {
								//if the button element children is an array
								for (var j = 0; j < childName.length; j++) {
									if (
										childName[j] &&
										childName[j].trim() !== ""
									) {
										childName = childName[j];
										break;
									}
								}
							}
							if (Array.isArray(childName)) {
								//this condition occurs when the child name is dynamically modified (which inturn adds '<!-- /react-text -->' to the string).
								childName = "";
							}
							if (
								actionName.indexOf(
									childName.trim().toLowerCase()
								) >= 0 &&
								!buttonElement.props["data-ignoreReadOnly"]
							) {
								// if the button has any of the action mentioned in 'actionName', it disables the action.
								childWithProps = React.Children.map(
									buttonElement,
									child =>
										React.cloneElement(child, {
											onClick: "",
											disabled: true,
											data: "ReadOnly"
										})
								);
							}
						} else {
							// if the element is not a button, it parses to next child element
							childWithProps = React.Children.map(
								buttonElement,
								child =>
									React.cloneElement(child, {
										children: self.getChildElement(
											self,
											child.props.children
										)
									})
							);
						}
					}
					return childWithProps;
				}
			}
		);

		return childElements;
	};

	render() {
		const {
			showCloseIcon,
			hideFooter
		} = this.props;
		const { open, showPortal } = this.state;
		if (!showPortal) return null;

		const MODEL_CLASS_NAME =
			"p3-modal " +
			(this.props.modelClassName || "") +
			(this.state.isMaximize ? " disable-max-height" : "");
		const HEADER_CLASS_NAME =
			"header " + (this.props.headerClassName || "");
		//if the popup height is small, there is no need of overflow:hidden porperty in '.content' class. to override that, 'p3-overflow-visible' class is added.
		const CONTENT_CLASS_NAME =
			"content h-100 " +
			(this.props.contentClassName || "") +
			(this.props.isOverFlowVisible ? " p3-overflow-visible" : "");
		const FOOTER_CLASS_NAME =
			"footerContent " + (this.props.footerClassName || "");
		const MODEL_STYLE = {
			width: this.props.width,
			height: this.props.height
		};

		return (
			<Portal
				container={
					this.props.renderIn
						? document.getElementById(this.props.renderIn)
						: document.body
				}
			>
				{open && (
					<div
						ref={input => (this.overlay = input)}
						className={
							this.props.isModal ? "overlay" : "overlay no-modal"
						}
						onClick={this.onClickOverlay}
					>
						<div
							data-auto-id={
								this.props.autoId && this.props.autoId
							}
							className={MODEL_CLASS_NAME}
							style={MODEL_STYLE}
							ref={input => (this.modal = input)}
						>
							<div
								className={HEADER_CLASS_NAME}
								onMouseDown={event => this.startMoving(event)}
								onMouseUp={() => this.stopMoving()}
							>
								<span className="p3-color-black p3-header-text">
									{this.props.titleStatusIconClass !== "" ? (
										<span
											className={`p3-popup-title-icon ${this.props.titleStatusIconClass}`}
										/>
									) : null}
									{this.props.title}
								</span>
								<div className="popup-icon-container">
									{showCloseIcon && (
										<CloseIcon
											onClick={this.onClickCloseIcon}
											className="gap-pointer"
										/>
									)}
								</div>
							</div>

							{this.props.enableScrollBar && (
								<ScrollArea
									ref={this.props.onScrollRef}
									className="p3-scrollbar-area p3-content-color"
									contentClassName={
										this.props.scrollBarFullHeight
											? " p3-scrollbar-content-full-height"
											: " p3-scrollbar-content"
									}
									smoothScrolling={true}
									verticalContainerStyle={{
										width: "6px",
										opacity: ".4",
										zIndex: "1",
										right: "3px"
									}}
									verticalScrollbarStyle={{
										width: "5px",
										borderRadius: "2.5px"
									}}
								>
									<div
										className={CONTENT_CLASS_NAME}
										id={
											this.props.contentId
												? this.props.contentId
												: ""
										}
									>
										{React.Children.map(
											this.props.children,
											(child, i) => {
												if (
													child &&
													child.type !== P3Footer
												)
													return child;
											}
										)}
									</div>
								</ScrollArea>
							)}
							{!this.props.enableScrollBar && (
								<div
									className={
										CONTENT_CLASS_NAME + " p3-content-color"
									}
									id={
										this.props.contentId
											? this.props.contentId
											: ""
									}
								>
									{React.Children.map(
										this.props.children,
										(child, i) => {
											if (
												child &&
												child.type !== P3Footer
											)
												return child;
										}
									)}
								</div>
							)}

							{!hideFooter &&
								React.Children.map(
									this.props.children,
									(child, i) => {
										if (child && child.type === P3Footer) {
											return (
												<div
													className={
														FOOTER_CLASS_NAME
													}
												>
													{child}
												</div>
											);
										}
									}
								)}
						</div>
						{this.props.isProgress && (
							<P3LoadingBar
								statusIcon={this.props.progressStatusIcon}
								message={this.props.progressMessage}
							/>
						)}
					</div>
				)}
			</Portal>
		);
	}
}
