import { gql } from "@apollo/client";
export const LOAD_DATA = gql`
  query($number_of_cameras:Int!, $frames_per_second:Int!,
        $recording_profile:Int!,$resolution:String!,$retention_days:Int!,
        $compression: String!, $compression_ratio: Int!,  $scene_activity: Int! ){
    compute_bandwidth_storage(camdata:{ compression:$compression,
                                        compression_ratio:$compression_ratio,
                                        number_of_cameras: $number_of_cameras,
                                        frames_per_second: $frames_per_second,
                                        recording_profile: $recording_profile,
                                        resolution: $resolution,
                                        retention_days: $retention_days,
                                        scene_activity: $scene_activity
                                      }) {
      bandwidth
      bit_rate
      disk_space
      frame_size
    }
  }
`;

export const LOAD_NVR = gql`
  query($total_capacity:Float!, $total_bandwidth_mbps:Float! ){
          retrieve_recommended_nvrs(bandwidthStorageData:{ total_bandwidth_mbps:$total_bandwidth_mbps,
            total_capacity:$total_capacity
                                      }) {
                                        nics {
                                          display
                                        }
                                        num_nodes
                                        product_class
                                        product_class_description
                                        product_class_display
                                        recommendation_details
                                        recommendation_type
                                        total_bandwidth_mbps
                                        total_capacity_tb
                                        uuid
                                        model
                                        drive_description
    }
  }
`;

export const LOAD_USERS = gql`
query{
    camera_resolutions{
      description
      name
    }
    compression_ratios{
      description
      name
      value
    }
    compressions{
      name
      description
    }
    recording_profiles{
      description
      name
      value
    }
    scene_activities{
      description
      name
      value
    }
  }

`;