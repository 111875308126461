/** @format */
import PropTypes from "prop-types";
import React, { Component } from "react";
import "./p3-accordion.scss";

/**
 * <Accordion /> description
 *
 * @param {object} props
 */
const DISPLAY_STATUS = {
	SHOW: "show",
	HIDE: "hide"
};

const TRANSITION = {
	DURATION: 250,
	FUNCTION: "cubic-bezier(0, 1, 0.5, 1)"
};

const convertArrayOfStringToNumbers = arrOfStrings => {
	let convertedArr = [];
	arrOfStrings.forEach((num, i) => {
		const indexNum = parseInt(num, 10);
		if (indexNum === 0 || indexNum) {
			convertedArr.push(indexNum);
		}
	});
	return convertedArr;
};

export class Accordion extends Component {
	constructor(props) {
		super(props);
		const {
			defaultActivePanelIndices,
			disabledPanelIndices,
			isMultiOpen
		} = this.props;
		this.state = {
			activePanelIndices: [
				...convertArrayOfStringToNumbers(defaultActivePanelIndices)
			],
			defaultActivePanelIndices,
			isMultiOpen,
			disabledPanelIndices: convertArrayOfStringToNumbers(
				disabledPanelIndices
			)
		};
		this.lastCall = 0;
		this.accordionEle = React.createRef(); // Accordion container Element ref
	}

	componentDidMount() {
		const { collapseAll, openAll, dataSource } = this.props;
		const { isMultiOpen, disabledPanelIndices } = this.state;
		let activePanelIndices = [...this.state.activePanelIndices];
		let activePanelIndicesLength = activePanelIndices.length;
		let disabledPanelIndicesLength = disabledPanelIndices.length;

		// open all the panels by default
		if (isMultiOpen && openAll && dataSource) {
			let dataSourceLength = dataSource.length;
			activePanelIndices = [
				...Array.from({ length: dataSourceLength }, (v, k) => k)
			];
		}
		// If multiple open is not enabled, show only one item
		if (!isMultiOpen && activePanelIndicesLength > 1) {
			activePanelIndices.splice(0, activePanelIndicesLength - 1); // Last item is given higher preference.
		}
		// Remove disabled indices from active indices.
		if (disabledPanelIndicesLength) {
			activePanelIndices = activePanelIndices.filter(
				index => disabledPanelIndices.indexOf(index) < 0
			);
		}
		// open the next valid panel, if there is no active panels
		const accordionPanelLength =
			this.accordionEle.current &&
			this.accordionEle.current.children &&
			this.accordionEle.current.children.length;
		if (
			!collapseAll &&
			!activePanelIndices.length &&
			accordionPanelLength > 1 &&
			disabledPanelIndicesLength !== accordionPanelLength
		) {
			let i = accordionPanelLength - 1;
			let index = 0;
			// cyclic rotation to choose the next panel, which is not in disabled state.
			while (
				disabledPanelIndices.indexOf(index) !== -1 &&
				index !== accordionPanelLength - 1 &&
				i
			) {
				index = index + 1;
				i--;
			}
			activePanelIndices = [index];
		}
		// if activePanelIndices is set to '-1', collapse all panels by default.
		if (
			activePanelIndices.length === 1 &&
			(activePanelIndices[0] === -1 || activePanelIndices[0] === "-1")
		) {
			activePanelIndices = [];
		}
		this.setState({
			activePanelIndices
		});
	}
	/* 
        shouldComponentUpdate(nextProps, nextState) {
            if (JSON.stringify(this.state.activePanelIndices) !== JSON.stringify(nextState.activePanelIndices)
                || JSON.stringify(this.state.disabledPanelIndices) !== JSON.stringify(nextState.disabledPanelIndices)
                || this.state.isMultiOpen !== nextState.isMultiOpen
                || JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
                return true;
            } 
            return false;
        }
     */
	static getDerivedStateFromProps(nextProps, prevState) {
		let activePanelIndices = [...prevState.activePanelIndices];
		let newStateObj = {};
		// activePanelIndices is changed dynamically
		//console.log("active panel indices   ",nextProps);
		//console.log("prev panel indices   ",prevState.activePanelIndices);
		if (
			nextProps.defaultActivePanelIndices.sort().toString() !==
			prevState.activePanelIndices.sort().toString()
		) {
			newStateObj.defaultActivePanelIndices = [
				...convertArrayOfStringToNumbers(
					nextProps.defaultActivePanelIndices
				)
			];
			newStateObj.activePanelIndices = [
				...convertArrayOfStringToNumbers(
					nextProps.defaultActivePanelIndices
				)
			];
			activePanelIndices = [...newStateObj.activePanelIndices];
			// if activePanelIndices is set to '-1', collapse all panels by default.
			if (
				activePanelIndices.length === 1 &&
				(activePanelIndices[0] === -1 || activePanelIndices[0] === "-1")
			) {
				activePanelIndices = [];
				newStateObj.activePanelIndices = [];
			}
		}
		//console.log("active active   ", nextProps.defaultActivePanelIndices);
		// isMultiOpen is changed dynamically, if more than one panel is open, show only the last panel
		if (nextProps.isMultiOpen !== prevState.isMultiOpen) {
			const activePanelIndicesLength = activePanelIndices.length;
			if (activePanelIndicesLength > 1) {
				activePanelIndices.splice(0, activePanelIndicesLength - 1);
				newStateObj.isMultiOpen = nextProps.isMultiOpen;
				newStateObj.activePanelIndices = activePanelIndices;
			} else {
				newStateObj.isMultiOpen = nextProps.isMultiOpen;
			}
		}
		// disabledPanelIndices is changed dynamically,
		if (
			nextProps.disabledPanelIndices.sort().toString() !==
			prevState.disabledPanelIndices.sort().toString()
		) {
			let disabledPanelIndices = convertArrayOfStringToNumbers(
				nextProps.disabledPanelIndices
			);
			activePanelIndices = activePanelIndices.filter(
				index => disabledPanelIndices.indexOf(index) < 0
			);
			newStateObj.activePanelIndices = activePanelIndices;
			newStateObj.disabledPanelIndices = disabledPanelIndices;
		}
		if (Object.keys(newStateObj).length === 0) return null;
		else {
			return newStateObj;
		}
	}

	toggleAccordionPanel = (selectedIndex, e = null) => {
		this.currentCall = new Date().getTime();
		if (
			this.currentCall - this.lastCall >=
			this.props.transitionDuration + 30
		) {
			// Throtling to avoid continous click
			this.lastCall = new Date().getTime();
			const panelElement = this.accordionEle.current.children[
				selectedIndex
			];
			const headerElement = panelElement.getElementsByClassName(
				"m-summary"
			)[0];
			const sectionElement = panelElement.getElementsByClassName(
				"m-acc-content-panel"
			)[0];
			if (panelElement) {
				if (e && e.type === "keydown") {
					headerElement.focus();
				}
				const { activePanelIndices, isMultiOpen } = this.state;
				if (activePanelIndices.indexOf(selectedIndex) === -1) {
					// If selected index is NOT present in activePanelIndices, show the selected panel
					if (!isMultiOpen && activePanelIndices.length) {
						let eleTohide = this.accordionEle.current.children[
							activePanelIndices[0]
						];
						eleTohide = eleTohide.getElementsByClassName(
							"m-acc-content-panel"
						)[0];
						// we are passing 4th param (event) as null to indicate, that we closing one panel manually in accordion.
						// Used to distinguish whether panel closed by user or closed internally. Used to triger change events accordingly.
						this.hideEle(
							eleTohide,
							activePanelIndices[0],
							this.transitionStarted,
							this.transitionCompleted,
							null
						);
					}
					this.showEle(
						sectionElement,
						selectedIndex,
						this.transitionStarted,
						this.transitionCompleted,
						e
					);
				} else {
					// If selected index is present in activePanelIndices, hide the selected panel
					if (this.state.isMultiOpen || this.props.collapseAll)
						this.hideEle(
							sectionElement,
							selectedIndex,
							this.transitionStarted,
							this.transitionCompleted,
							e
						);
				}
			}
		}
	};

	showEle = (
		target,
		selectedIndex = 0,
		transitionStarted = null,
		transitionCompleted = null,
		e = null
	) => {
		const args = {
			event: e,
			target,
			selectedIndex,
			elemStatus: DISPLAY_STATUS.SHOW
		};
		this.props.beforeChange && this.props.beforeChange(args);
		transitionStarted && transitionStarted(args);
		let targetStyle = target.style;
		targetStyle.display = "block";
		let height = target.offsetHeight;
		targetStyle.overflow = "hidden";
		targetStyle.height = 0;
		targetStyle.paddingTop = 0;
		targetStyle.paddingBottom = 0;
		targetStyle.marginTop = 0;
		targetStyle.marginBottom = 0;
		targetStyle.boxSizing = "border-box";
		targetStyle.transitionProperty = "height, margin, padding";
		targetStyle.transitionDuration = this.props.transitionDuration + "ms";
		targetStyle.transitionTimingFunction = this.props.transitionFunction;
		targetStyle.height = height + "px";
		targetStyle.removeProperty("padding-top");
		targetStyle.removeProperty("padding-bottom");
		targetStyle.removeProperty("margin-top");
		targetStyle.removeProperty("margin-bottom");
		window.setTimeout(() => {
			// remove all the property added to style object
			targetStyle.removeProperty("height");
			targetStyle.removeProperty("overflow");
			targetStyle.removeProperty("transition-duration");
			targetStyle.removeProperty("transition-property");
			targetStyle.removeProperty("box-sizing");
			transitionCompleted && transitionCompleted(args);
			this.props.afterChange && this.props.afterChange(args);
		}, this.props.transitionDuration);
	};

	hideEle = (
		target,
		selectedIndex = 0,
		transitionStarted = null,
		transitionCompleted = null,
		e = null
	) => {
		const args = { event: e, target, selectedIndex, elemStatus: "hide" };
		this.props.beforeChange &&
			(this.props.isMultiOpen || this.props.collapseAll) &&
			e &&
			this.props.beforeChange(args);
		transitionStarted && transitionStarted(args);
		let targetStyle = target.style;
		targetStyle.transitionProperty = "height, margin, padding";
		targetStyle.transitionDuration = this.props.transitionDuration + "ms";
		targetStyle.transitionTimingFunction = this.props.transitionFunction;
		targetStyle.boxSizing = "border-box";
		targetStyle.height = target.offsetHeight + "px";
		targetStyle.overflow = "hidden";
		targetStyle.height = 0;
		targetStyle.paddingTop = 0;
		targetStyle.paddingBottom = 0;
		targetStyle.marginTop = 0;
		targetStyle.marginBottom = 0;
		window.setTimeout(() => {
			// remove all the property added to style object
			targetStyle.display = "none";
			targetStyle.removeProperty("height");
			targetStyle.removeProperty("padding-top");
			targetStyle.removeProperty("padding-bottom");
			targetStyle.removeProperty("margin-top");
			targetStyle.removeProperty("margin-bottom");
			targetStyle.removeProperty("overflow");
			targetStyle.removeProperty("transition-duration");
			targetStyle.removeProperty("transition-property");
			targetStyle.removeProperty("box-sizing");
			transitionCompleted && transitionCompleted(args);
			this.props.afterChange &&
				(this.props.isMultiOpen || this.props.collapseAll) &&
				e &&
				this.props.afterChange(args);
		}, this.props.transitionDuration);
	};

	transitionStarted = args => {
		let { target, elemStatus } = args;
		target.classList.add("m-transitioning");
		if (!this.props.hideOpenCloseArrow) {
			const accTitleEle = target.previousElementSibling;
			const arrEle = accTitleEle.querySelectorAll("[data-icon]")[0];
			if (elemStatus === DISPLAY_STATUS.SHOW) {
				arrEle.classList.remove(this.props.collapseIcon);
				arrEle.classList.add(this.props.expandIcon);
			} else {
				arrEle.classList.remove(this.props.expandIcon);
				arrEle.classList.add(this.props.collapseIcon);
			}
		}
	};

	transitionCompleted = args => {
		let { target, selectedIndex, elemStatus } = args;
		let activePanelIndices = [...this.state.activePanelIndices];
		if (elemStatus === DISPLAY_STATUS.SHOW) {
			activePanelIndices.push(selectedIndex);
		} else {
			const itemToRemove = activePanelIndices.indexOf(selectedIndex);
			activePanelIndices.splice(itemToRemove, 1);
		}
		this.setState(
			{
				activePanelIndices
			},
			() => {
				target.classList.remove("m-transitioning");
				const transitionArgs = { activePanelIndices };
				this.props.afterTransition &&
					this.props.afterTransition(transitionArgs);
			}
		);
	};

	render() {
		const {
			toggleAccordionPanel,
			props: {
				titleRenderer,
				contentRenderer,
				className,
				dataSource,
				titleField,
				contentField,
				classNameField
			},
			state: { activePanelIndices, disabledPanelIndices }
		} = this;
		let children = React.Children.toArray(this.props.children);
		return (
			// TODO:: Need to check whether in future, 'div' tags for summary and content-panel can be replaced with 'details' and 'summary' tags
			<div
				className={`m-accordion ${className}`}
				ref={this.accordionEle}
				aria-label="Accordion Control Group Buttons"
			>
				{dataSource.length // If accordion is implemented using object type, render using datasource
					? dataSource.map((child, index) => (
							<AccordionPanel
								key={child.id || index}
								currentPanelIndex={index} // since we cannot make use of key
								isOpen={
									activePanelIndices.indexOf(index) !== -1
								}
								onClick={
									disabledPanelIndices.indexOf(index) ===
										-1 && toggleAccordionPanel
								}
								universalTitleRenderer={titleRenderer}
								parentRef={this.accordionEle}
								{...this.props}
								child={child}
								title={child[titleField]}
								className={child[classNameField] || ""}
								disabledPanelIndices={disabledPanelIndices}
							>
								{contentRenderer
									? contentRenderer({
											content:
												child[contentField] || null,
											index,
											child,
											props: this.props
									  })
									: child[contentField]}
							</AccordionPanel>
					  ))
					: children &&
					  children.map((
							child,
							index // If accordion is implemented using composition pattern, render using datasource
					  ) => (
							<AccordionPanel
								key={index}
								currentPanelIndex={index} // since we cannot make use of key
								isOpen={
									activePanelIndices.indexOf(index) !== -1
								}
								onClick={
									disabledPanelIndices.indexOf(index) ===
										-1 && toggleAccordionPanel
								}
								universalTitleRenderer={titleRenderer}
								parentRef={this.accordionEle}
								{...this.props}
								{...child.props}
								className={child.props.className || " "}
								disabledPanelIndices={disabledPanelIndices}
							>
								{child.props.children}
							</AccordionPanel>
					  ))}
			</div>
		);
	}
}

export class AccordionPanel extends React.Component {
	onClick = (e, currentPanelIndex) => {
		this.props.onClick && this.props.onClick(currentPanelIndex, e);
	};

	handleKeyDown = (e, currentPanelIndex) => {
		const keyCode = e.which;
		if (
			keyCode === 40 ||
			keyCode === 38 ||
			keyCode === 13 ||
			keyCode === 35 ||
			keyCode === 36
		) {
			const { parentRef, onClick } = this.props;
			const childs = parentRef.current.children;
			let panelLength = childs.length;
			let index = currentPanelIndex; // This line makes Enter key(keyCode === 13) works by default
			if (keyCode === 40) {
				// down arrow
				index = index + 1;
				if (index === panelLength && panelLength > 1) {
					index = 0;
				}
			} else if (keyCode === 38) {
				// up arrow
				index = index - 1;
				if (index < 0 && panelLength > 1) {
					index = panelLength - 1;
				}
			} else if (keyCode === 36) {
				// Home Key
				index = 0; // Move to the first item
			} else if (keyCode === 35) {
				// End key
				index = panelLength - 1; // Move to the last item
			}
			let i = panelLength - 1; // Already we have moved to next / prev panel, so panelLength - 1.
			// cyclic rotation to choose the next / prev panel, if the index(panel) we have selected is in disabled state.
			while (this.props.disabledPanelIndices.indexOf(index) !== -1 && i) {
				if (keyCode === 40 || keyCode === 36) {
					// down or home key
					index = index + 1;
					if (index === panelLength && panelLength > 1) {
						index = 0;
					}
					i--;
				} else if (keyCode === 38 || keyCode === 35) {
					// up or End key
					index = index - 1;
					if (index < 0 && panelLength > 1) {
						index = panelLength - 1;
					}
					i--;
				}
			}
			onClick && onClick(index, e);
		}
	};

	render() {
		const {
			onClick,
			handleKeyDown,
			props: {
				isContentBasedExpand,
				isExpandable,
				child,
				contentField,
				children,
				isOpen,
				title,
				titleRenderer,
				universalTitleRenderer,
				currentPanelIndex,
				className,
				hideOpenCloseArrow,
				openOnHover,
				disabledPanelIndices,
				enableKeyboardNavigation,
				disabled,
				collapseIcon,
				expandIcon
			}
		} = this;
		const isDisabled = disabledPanelIndices.length
			? disabledPanelIndices.indexOf(currentPanelIndex) !== -1
			: disabled;
		// Used to block expand on click the panel and arrow button
		let showExpand = isContentBasedExpand && !child[contentField];
		return (
			<div
				className={`m-details ${isOpen ? "m-active" : ""} ${
					isDisabled ? "m-disabled" : ""
				} ${className}`}
				aria-expanded={isOpen ? "true" : "false"}
			>
				<div
					tabIndex="-1"
					className="m-summary"
					data-panel-index={currentPanelIndex}
					role="heading"
					
					onMouseOver={
						openOnHover && !isOpen
							? e => onClick(e, currentPanelIndex)
							: null
					}
					onKeyDown={
						!showExpand && enableKeyboardNavigation
							? e => handleKeyDown(e, currentPanelIndex)
							: null
					}
				>
					{!hideOpenCloseArrow && (
						<div className="arrow-cont">
						<div
							data-icon="m-acc-panel-toggle-arrow"
							onClick={e => !showExpand && onClick(e, currentPanelIndex)}
							className={
								(isOpen ? expandIcon : collapseIcon) +
								(showExpand ? " hide-arrow" : "")
							}
						/>
						</div>
					)}
					{// use render props pattern to render title.
					titleRenderer && typeof titleRenderer === "function"
					 	? titleRenderer(
								this.props,
								currentPanelIndex,
								title,
								isExpandable
						)
					: universalTitleRenderer &&
					  typeof universalTitleRenderer === "function"
					? universalTitleRenderer(this.props, currentPanelIndex)
					: <span>{this.props.title} ||
							<span>{`Panel-${currentPanelIndex + 1}`}</span>
						</span>
					}
					
				</div>
				<div
					className="m-acc-content-panel"
					style={{ display: isOpen ? "block" : "none" }}
					role="region"
					aria-hidden={isOpen ? "false" : "true"}
				>
					{children}
				</div>
			</div>
		);
	}
}

AccordionPanel.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	title: PropTypes.string,
	titleRenderer: PropTypes.func
};

Accordion.propTypes = {
	className: PropTypes.string,
	defaultActivePanelIndices: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.number),
		PropTypes.arrayOf(PropTypes.string)
	]),
	disabledPanelIndices: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.number),
		PropTypes.arrayOf(PropTypes.string)
	]),
	collapseAll: PropTypes.bool, // ::TODO Need to check whether we need to keep collapseAll in state or not. Is that needed?
	isMultiOpen: PropTypes.bool,
	openOnHover: PropTypes.bool,
	enableKeyboardNavigation: PropTypes.bool,
	hideOpenCloseArrow: PropTypes.bool,
	collapseIcon: PropTypes.string,
	expandIcon: PropTypes.string,
	transitionDuration: PropTypes.number,
	transitionFunction: PropTypes.string,
	dataSource: PropTypes.arrayOf(PropTypes.object),
	titleField: PropTypes.string,
	contentField: PropTypes.string,
	classNameField: PropTypes.string,
	titleRenderer: PropTypes.func,
	contentRenderer: PropTypes.func,
	beforeChange: PropTypes.func,
	afterChange: PropTypes.func,
	afterTransition: PropTypes.func
};

Accordion.defaultProps = {
	className: "",
	defaultActivePanelIndices: [0],
	disabledPanelIndices: [],
	collapseAll: false,
	openAll: false,
	isMultiOpen: false,
	openOnHover: false,
	enableKeyboardNavigation: true,
	hideOpenCloseArrow: false,
	expandIcon: "m-arrow-up",
	collapseIcon: "m-arrow-down",
	transitionDuration: TRANSITION.DURATION,
	transitionFunction: TRANSITION.FUNCTION,
	dataSource: []
};
