import React, { useState } from "react";
import './App.css';
import MainView from './sizer/main-view';
import 'bootstrap/dist/css/bootstrap.css';
import { useKeycloak } from "@react-keycloak/web";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink
} from "@apollo/client";
import { P3LoadingBar, P3StatusBarStatus } from "./components/status-bar/status-bar";

const httpLink = createHttpLink({ uri: '/v1/graphql' });

const client = new ApolloClient({
    cache: new InMemoryCache({ addTypename: false }),
    link: httpLink,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
    }
});

const logout = (keycloak) => {
    keycloak && keycloak.logout();
}
const profile = () => {
    window.open("/auth/realms/qsizer/account");
}
function App() {
    const { keycloak } = useKeycloak();
    const [username, setUsername] = useState("");
    const [isLoading, setLoading] = useState(true);
    keycloak.loadUserProfile().then(function (data) {
        setUsername(data.firstName + " " + data.lastName);
    }).catch(error => setLoading(false))
        .finally(() => setLoading(false))

    if (isLoading) {
        return (
            <P3LoadingBar
                statusIcon={P3StatusBarStatus.LOADING}
                message="Loading"
            />
        )
    }
    return (
        <>
            {!isLoading && <div className="bg-header"><div className="container header-space">
                <div className="quantum-logo" />
                <div className="profile-menu">
                    <label type="button" className="dropdown-toggle pt-2 pr-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {username}
                    </label>
                    <div className="dropdown-menu dropdown-menu-right mt-2">
                        <button className="dropdown-item" onClick={profile} type="button">Profile</button>
                        <button className="dropdown-item" onClick={() => logout(keycloak)} type="button">Logout</button>
                    </div>
                </div>
            </div>
            </div>}
            <div className="container App">
                <ApolloProvider client={client}>
                    <MainView />
                </ApolloProvider>
            </div>
        </>
    );
}

export default App;
